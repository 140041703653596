import * as axios from 'axios'; 

axios.interceptors.request.use((req) => {
    const token = localStorage.getItem('jwt');
    if(token != null){
        req.headers.common['X-auth-token'] = token; 
        return req;  
    }else{
        return req;
    }
}); 
