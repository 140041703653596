import React, {Component} from 'react'; 
import PreviewFormProfil from '../../../../Shared/Components/user/PreviewFormProfil';
import PreviewFormPassword from '../../../../Shared/Components/user/PreviewFormPassword';
import Loader from '../../../../Shared/Components/Loader';
import Card from '@material-ui/core/Card';

export default class PreviewInfoUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            user: undefined, 
            isLoaderData: true
        }
    }

    componentDidMount(){
        console.log('test: ', this.props.user)
        this.setState({user: this.props.user, isLoaderData: false});
    } 

    render(){
        return(
            <div className="container">
                {!this.state.isLoaderData ? (
                    <div className="row d-flex flex-direction-column justify-content-center">
                        <div className="col-12 col-md-10 col-xl-6">
                            <div className="w-100">
                                <Card className="card">
                                    <PreviewFormProfil user={this.state.user} />
                                </Card>

                                <Card className="card">
                                    <PreviewFormPassword user={this.state.user} />
                                </Card>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                        <Loader />
                    </div>
                )}
            </div>
        ); 
    }
}