import React, {Component} from 'react'; 
import Button from '@material-ui/core/Button';
import Loader from '../Loader';
import { createCardPDF, createCardPrimaryPDF } from '../../../API/Admin/Card/Card';

export default class PreViewPDF extends Component{
    constructor(props){
        super(props)
        this.state = {
          isLoaderData: true, 
          urlPDF: undefined, 
          idPDF: undefined, 
          typeCard: undefined
        }
    }

    componentDidMount(){
        console.log('idPDF :',this.props.typeCard)
        this.setState({
            urlPDF: this.props.urlPDF, 
            idPDF: this.props.idPDF,
            typeCard: this.props.typeCard, 
            isLoaderData: false
        }); 
        document.getElementById('app').style.overflow = 'hidden';
    }

    onPress(){
        document.getElementById('app').style.overflow = 'auto';
        this.props.recepStatePreviewPDF(false, undefined); 
    }

    /**
     * Regénération PDF
     */
    _generatePDF(){
        this.setState({isLoaderData: true});
        if(this.state.typeCard === 'card'){
            createCardPDF({id: this.state.idPDF}).then((response) => {
                this.setState({isLoaderData: false}); 
            }).catch((err) => {
                this.setState({isLoaderData: false});             
            }); 
        }else{
            createCardPrimaryPDF({id: this.state.idPDF}).then((response) => {
                this.setState({isLoaderData: false}); 
            }).catch((err) => {
                this.setState({isLoaderData: false});             
            }); 
        }
    }


    render(){
        return(
            <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{
                    position: 'absolute', 
                    top: 0, bottom: 0, left: 0, right: 0, background: '#525659',
                    zIndex: 99999, marginTop: this.props.marginTop
                }}>
                {
                    !this.state.isLoaderData ? (
                            <iframe src={this.state.urlPDF} title="PDF" align="top" className="iframe_preview_pdf"
                            frameBorder="0" scrolling="auto" target="Message"></iframe>
                    ) :
                    (
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                            <Loader />
                            <p style={{color: 'white', fontSize: 20}}>Création du PDF en cours</p>
                        </div>
                    )
                }
                <div className="footer_preview_pdf d-flex justify-content-around">
                    <Button style={{backgroundColor: '#3c6382', color: 'white'}} 
                        onClick={() => this.onPress()}>
                        Fermer la fiche
                    </Button>
                    {
                        !this.state.isLoaderData ? (
                            <Button style={{backgroundColor: '#3c6382', color: 'white'}} 
                                onClick={() => this._generatePDF()}>
                                Regénérer PDF
                            </Button>
                        ) : <></>
                    }
                </div>
            </div>
        )
    }
}