import React, { Component } from 'react'; 
import './Operation.css';

export default class Operation extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }

    componentDidMount(){}

    render(){
        var source_phone_fiche = require('../../../Shared/images/phone-fiche.png'); 
        return(
            <div className="primary container_operation">
                <div className="row">
                    <div className="col-12">
                        <h2>Qu'est ce que Métré Pro ?</h2>
                        <div className="block">
                            <div className="content">
                            <img className="img_phone_fiche" src={source_phone_fiche} alt="Téléphone" />
                                <div>
                                    <h3>Présentation</h3>
                                    <p> 
                                        Métré pro est l'application mobile conçue pour les professionnels de la 
                                        pose de menuiserie. 
                                        
                                        Remplissez et envoyez vos fiches de mesures rapidement et facilement à vos
                                        fournisseurs. 
                                    </p>
                                    <p>Liste des fiches disponibles sur l'application:</p>
                                    <div className="container_primary_ul">
                                        <div className="container_ul">
                                            <h4>Fiches exterieurs:</h4>
                                            <ul className="ul_liste_fiches_un">
                                                <li>Fenêtres</li>
                                                <li>Volets Roulants / solaires</li>
                                                <li>Portes d'entrées</li>
                                                <li>Persiennes</li>
                                                <li>Stores Bannes</li>
                                            </ul>
                                        </div>
                                        <div className="container_ul">
                                            <h4>Fiches interieurs:</h4>
                                            <ul className="ul_liste_fiches_deux">
                                                <li>Carrelage</li>
                                                <li>Parquet collé</li>
                                                <li>Parquet flottant</li>
                                                <li>Volet roulant</li>
                                                <li>Peinture</li>
                                                <li>Salle de bain</li>
                                            </ul>
                                        </div>
                                        <div className="container_ul container_ul_3">
                                            <h4>Fiches futurs:</h4>
                                            <ul className="ul_liste_fiches_trois">
                                                <li>Cuisine</li>
                                                <li>Dressing</li>
                                                <li>Électricité</li>
                                                <li>Escalier</li>
                                                <li>Volet battant</li>
                                                <li>Porte d'interieur</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3>Essai gratuit</h3>
                                    <p> 
                                        Essayez Métré Pro gratuitement pendant un mois,
                                        si vous êtes  satisfait, adhérez à un abonnement pour 
                                        profiter pleinement des services. L'abonnement est sans engagement et non renouvelable 
                                        automatiquement.
                                    </p>
                                </div>
                                <div>
                                    <h3>Abonnement</h3>
                                    <p> 
                                        Choisissez l'offre qui convient à vos besoins. Vous avez 3 formules à disposition:
                                    
                                    </p>
                                    <ul className="ul_liste_abonnement">
                                        <li>Premium: Abonnement valable 1 mois - 10€ HT</li>
                                        <li>Secondary: Abonnement valable 3 mois - 28€ HT</li>
                                        <li>Tertiary: Abonnement valable 12 mois - 100€ HT</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}