import axios from 'axios'; 
import { BASE_URL } from '../baseURL'; 

/**
 * Methode pour modification du mot de passe de l'utilisateur
 * @param {login} login 
 */
export function forgetPasswordUser(login){
    return axios.post(`${BASE_URL}/send-new-password`, login); 
}

/**
 * Methode pour reccuperer les informations sur l'utilisateur
 */
export async function getCurrentUser(){
    const ID_TOKEN = await localStorage.getItem('idToken')
    return axios.get(`${BASE_URL}/auth-tokens/` + ID_TOKEN); 
}

// /**
//  * Methode de deconnexion de l'utilsateur avc destruction de token
//  */
// export async function getDeleteSessionUser(){
//     ID_TOKEN = await AsyncStorage.getItem('idToken')
//     return axios.delete(`${BASE_URL}/auth-tokens/` + ID_TOKEN)
// }

/**
 * Methode pour enregistrer un nouvel utilisateur
 * @param {civility, firstname, lastname, email, plainPassword, statusProfil, buisness} form 
 */
export  function createNewUser(form){
    return axios.post(`${BASE_URL}/create-users`, form); 
}

/**
 * Methode pour envoyer un email de confirmation d'inscription
 * @param {email} form 
 */
export  function sendEmailNewUser(form){
    return axios.post(`${BASE_URL}/send-new-user`, form); 
}

/**
 * Methode pour modifier le mot de passe de l'utilisateur
 * @param {user} idUser 
 * @param {lastname, firsntame, buisness, statusProfil, civility} 
 */
export async function upadatedUser(idUser , data){
    return axios.patch(`${BASE_URL}/users/` + idUser, data
    ); 
}