import React, {Component} from 'react'; 
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PreviewInfoUser from './PreviewInfoUser';
import PreviewCardUser from './PreviewCardUser';
import PreviewSubscriptionUser from './PreviewSubscriptionUser';
import PreViewPDF from '../../../../Shared/Components/user/PreviewPDF';
import PreviewCardSingleUser from './PreviewCardSingleUser';
import Button from '@material-ui/core/Button';

export default class UserSingle extends Component{
    constructor(props){
        super(props)
        this.state = {
            statusSubscription: false,
            statusCard: false, 
            statusUser: true, 
            value: 0, 
            user: undefined, 
            isLoaderData: true, 
            statusPDF: false, 
            urlPDF: undefined, 
            statusSingleCard: false, 
            idCardSingle: undefined, 
            idPDF: undefined, 
            typeCard: undefined, 
        }
    }

    componentDidMount(){
        console.log('props: ', this.props)
        this.setState({user: this.props.user, isLoaderData: false});
    }

    /**
     * Fermeture de la fenetre
     */
    onPress(){
        this.props.onCloseUserSingle(false)
    }

    /**
     * Changement du menu
     */
    handleChange = (event, value) => {
        this.setState({ isLoaderData: false, value });
        if(value === 0){
            this.setState({statusCard: false, statusSubscription: false, statusUser: true})
        }else if(value === 1){
            this.setState({statusCard: true, statusSubscription: false, statusUser: false})
        }else if(value === 2){
            this.setState({statusCard: false, statusSubscription: true, statusUser: false})
        }
    };

    /**
     * Affichage du menu principal
     */
    _displayTabsPaper(){
        return(
            <Paper className="mt-2 w-100" square>
                <Tabs value={this.state.value} indicatorColor="primary" textColor="primary"
                onChange={this.handleChange} className="d-flex justify-content-center w-100">
                    <Tab label="Informations" />
                    <Tab label="Fiches"/>
                    <Tab label="Abonnements" />
                </Tabs>
            </Paper>
        ); 
    }

     /**
     * **************PDF**************
     */

    /**
     * Logique preview pdf
     */
    recepStatePreviewPDF = (statusPDF, urlPDF, idPDF, typeCard) => {
        this.setState({statusPDF: statusPDF, urlPDF: urlPDF, idPDF: idPDF, typeCard: typeCard}); 
    }

    /**
     * DisplayPDF
     */
    _displayPDF(){
        if(this.state.statusPDF){
            return(
                <PreViewPDF marginTop={0} recepStatePreviewPDF={this.recepStatePreviewPDF.bind(this)} 
                    urlPDF={this.state.urlPDF} idPDF={this.state.idPDF} typeCard={this.state.typeCard} /> 
            )
        }
    }

    /**
     * **************Card Single**************
     */

    /**
     * Logique preview single card
     */
    recepStatePreviewCard = (statusCard, idCard) => {
        this.setState({statusSingleCard : statusCard, idCardSingle: idCard}); 
    }

    statusWindowPreviewCardSingle(status){
        this.setState({statusSingleCard : status}); 
    }

    /**
     * Display card single
     */
    _displayCardSingle(){
        if(this.state.statusSingleCard){
            return(
                <PreviewCardSingleUser recepStatePreviewPDF={this.recepStatePreviewPDF.bind(this)} 
                    statusWindowPreviewCardSingle={this.statusWindowPreviewCardSingle.bind(this)} 
                    idCard={this.state.idCardSingle} /> 
            )
        }
    }

    render(){
        return(
            <div className="w-100" style={{marginBottom: 130}}>
                <div className="container w-100 h-100">
                    {this._displayTabsPaper()}
                    <div className="row">
                        <div className="col-12">
                        {
                            !this.state.isLoaderData ? 
                                <div>
                                    {
                                        this.state.statusUser ? 
                                            <PreviewInfoUser user={this.state.user} /> : <></>
                                    }
                                    {
                                        this.state.statusCard ? 
                                            <PreviewCardUser user={this.state.user} 
                                        recepStatePreviewPDF={this.recepStatePreviewPDF.bind(this)}
                                        recepStatePreviewCard={this.recepStatePreviewCard.bind(this)} /> : <></>
                                    }
                                    {
                                        this.state.statusSubscription ? 
                                            <PreviewSubscriptionUser user={this.state.user}
                                            recepStatePreviewPDF={this.recepStatePreviewPDF.bind(this)}
                                        /> : <></>
                                    }
                                </div>
                                : <></> 
                            }
                        </div>
                    </div>
                    <div className="footer_preview_pdf">
                        <Button style={{backgroundColor: '#3c6382', color: 'white'}} 
                            onClick={() => this.onPress()}>
                                Retour à la liste
                        </Button>
                    </div>
                </div>
                {this._displayPDF()}
                {this._displayCardSingle()}
            </div>
        ); 
    }
}