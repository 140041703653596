import axios from 'axios'; 
import { BASE_URL } from '../../baseURL'; 

/**
 * Methode pour réccuperer les fiches d'un utilisateur via son id
 */
export async function getCardByUser(user){
    return axios.get(`${BASE_URL}/card-administrator?user=${user}`);
}

/**
 * Methode pour réccuperer les fiches liés à la card mère
 */
export async function getCardPrimaryByCard(card){
    return axios.get(`${BASE_URL}/card-primary-administrator?card=${card}`);
}

/**
 * Methode pour création du PDF card
 */
export async function createCardPDF(form){
    return axios.post(`${BASE_URL}/create-card-pdf`, form);
}

/**
 * Methode pour création du PDF card primary
 */
export async function createCardPrimaryPDF(form){
    return axios.post(`${BASE_URL}/create-card-primary-pdf`, form);
}