import React, {Component} from 'react'; 

import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'; 

import moment from 'moment'; 
import 'moment/locale/fr'; 

export default class PreviewCard extends Component{
    constructor(props){
        super(props)
        this.state = {
            card: []
        }
    }

    componentDidMount(){
        console.log(this.props)
        this.setState({card: this.props.card})
    }

    render(){
        return(
            <div>
                {this.state.card.map((c, index) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar>
                                <RemoveRedEye style={{cursor: 'pointer',  height: 18}}
                                    onClick={() => this.props.recepStatePreviewPDF(true, c.uuid_card)} />
                            </Avatar>
                        </ListItemAvatar>
                            <ListItemText
                                primary={
                                    c.civility_card_client.charAt(0).toUpperCase() + 
                                        c.civility_card_client.substring(1).toLowerCase() 
                                        + ' ' +
                                    c.lastname_card_client.charAt(0).toUpperCase() + 
                                        c.lastname_card_client.substring(1).toLowerCase() 
                                        + ' ' + 
                                    c.firstname_card_client.charAt(0).toUpperCase() + 
                                        c.firstname_card_client.substring(1).toLowerCase() 
                                } 
                                secondary={
                                    moment(c.date_meter_card_poser.date).locale('fr').format('LLL')
                                }
                            />
                        <ListItemSecondaryAction>
                            <IconButton className="icon-grid" onClick={() => {
                                this.props.recepStatePreviewCard(true, c.id_card)
                            }}>
                                <DeleteIcon style={{color: '#d82424'}} aia-label="Delete"/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </div>
        )
    }

}