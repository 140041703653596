import React, { Component } from 'react'; 
import './user.css'
import { getCurrentUser } from '../../API/User/userService';
import Loader from '../../Shared/Components/Loader';
import Card from '@material-ui/core/Card';
import Footer from '../../Shared/Components/footer/Footer';
import PreviewFormPassword from '../../Shared/Components/user/PreviewFormPassword';
import PreviewFormProfil from '../../Shared/Components/user/PreviewFormProfil';


export default class ProfileUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoaderData: true, 
            user: []
        }
    }
 
    componentDidMount(){
        this.currentUser()
    }

    currentUser(){
        getCurrentUser().then(res => {
            this.setState({
                user: res.data, 
                isLoaderData: false
            });
        });
    }

    render(){
        return(
            <div className="container_user">
                <div className="container">
                    {!this.state.isLoaderData ? (
                        <div className="row d-flex flex-direction-column justify-content-center">
                            <div className="col-12 col-md-10 col-xl-6">
                                <div className="w-100">
                                    <Card className="card">
                                        <PreviewFormProfil user={this.state.user} />
                                    </Card>

                                    <Card className="card">
                                        <PreviewFormPassword user={this.state.user} />
                                    </Card>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                            <Loader />
                        </div>
                    )}
                </div>
                <Footer />
            </div>
        ); 
    }
}