import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Home from './Features/Home/Home';
import Header from './Shared/Components/header/Header';
import Utilisation from './Features/Mention/Utilisation';
import Confidentialite from './Features/Mention/Confidentialite';
import Vente from './Features/Mention/Vente';
import Mention from './Features/Mention/Mention';
import Loader from './Shared/Components/Loader';
import { isSignedIn } from './Shared/Auth/auth';
import Signin from './Features/Auth/Signin';
import Signup from './Features/Auth/Signup';
import ProfileUser from './Features/User/ProfileUser';
import FactureUser from './Features/User/FactureUser';
import CardUser from './Features/User/CardUser';
import './API/interceptor' 
import { getCurrentUser } from './API/User/userService';
import User from './Features/Admin/User/User';
import NewCMS from './Features/Admin/CMS/Components/NewCMS';
import ViewCMS from './Features/Admin/CMS/Components/ViewCMS';
import UpdateCMS from './Features/Admin/CMS/Components/UpdateCMS';
import Cms from './Features/Admin/CMS/Cms';
import HomeAdmin from './Features/Admin/HomeAdmin';

const PrivateRoute = ({component: Component, path, ...rest}) => {
  // console.log('prvate: ', rest.signedIn)
  return(
    <Route  path={ path } render={(props) => rest.signedIn ? 
      <Component { ...props } { ...rest } /> : <Redirect to="/connexion" /> }>
    </Route>
  ); 
}

const PrivateRouteAdmin = ({component: Component, path, ...rest}) => {
  // console.log('prvate: ', rest.signedIn)
  return(
    <Route  path={ path } render={(props) => rest.signedIn && rest.isAdmin ? 
      <Component { ...props } { ...rest } /> : <Redirect to="/connexion" /> }>
    </Route>
  ); 
}

const NotPrivateRoute = ({component: Component, path, ...rest}) => {
  // console.log('public: ', rest.signedIn)
  return(
    <Route  path={ path } render={(props) => !rest.signedIn ? 
      <Component { ...props } { ...rest } /> : <Redirect to="/admin-fae23pil" /> }>
    </Route>
  ); 
}



class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      signedIn: false, 
      isAdmin: false, 
      isLoading: true,
    }
  }

  componentDidMount(){
    this.isSignedIn();
  }

  isSignedIn = () => {
    isSignedIn().then((res) => {
      // console.log('res: ', res)
    if(res){
      getCurrentUser().then((response) => {
        if(response.data.roles[0] === 'ROLE_SUPER_ADMIN'){
          this.setState({isAdmin: true, signedIn: res, isLoading: false}); 
        }else{
          this.setState({isAdmin: false, signedIn: res, isLoading: false}); 
        }
      }); 
    }else{
      this.setState({isLoading: false})
    }
  });
  }

  render() {
    if(!this.state.isLoading){
      return(
        <Router>
          <div className="App">
            <Header signedIn={this.state.signedIn} isAdmin={this.state.isAdmin}/>
            <Switch>

              {/* Route not signin */}
              <NotPrivateRoute path="/accueil" component={Home} signedIn={this.state.signedIn}/> 
                  
              <NotPrivateRoute path="/inscription" component={Signin} signedIn={this.state.signedIn} /> 
              <NotPrivateRoute path="/connexion" component={Signup} signedIn={this.state.signedIn} /> 

              <NotPrivateRoute path="/utilisation" component={Utilisation}/>    
              <NotPrivateRoute path="/confidentialite" component={Confidentialite}/>     
              <NotPrivateRoute path="/vente" component={Vente}/>     
              <NotPrivateRoute path="/mention" component={Mention}/>     

              {/* Route signin */}
              <PrivateRoute path="/profil" component={ProfileUser} signedIn={this.state.signedIn} />
              <PrivateRoute path="/factures" component={FactureUser} signedIn={this.state.signedIn} />
              <PrivateRoute path="/fiches" component={CardUser} signedIn={this.state.signedIn} />
 
              {/* Route for admin */}
              <PrivateRouteAdmin path="/admin-fae23pil" component={HomeAdmin} signedIn={this.state.signedIn} isAdmin={this.state.isAdmin} />
              <PrivateRouteAdmin path="/user-list" component={User} signedIn={this.state.signedIn} isAdmin={this.state.isAdmin} />

              <PrivateRouteAdmin path="/cms" component={Cms} signedIn={this.state.signedIn} isAdmin={this.state.isAdmin}/>
              <PrivateRouteAdmin path="/add/:id" component={NewCMS} signedIn={this.state.signedIn} isAdmin={this.state.isAdmin}/>                     
              <PrivateRouteAdmin path="/view/:id" component={ViewCMS} signedIn={this.state.signedIn} isAdmin={this.state.isAdmin}/>      
              <PrivateRouteAdmin path="/update/:id" component={UpdateCMS} signedIn={this.state.signedIn} isAdmin={this.state.isAdmin}/> 

              {/* Redirect */}
              <Redirect to="/accueil" />

            </Switch>
          </div>
        </Router>
      ); 
    }else{
      return(
        <Loader />
      )
    }
  }
}

export default App;
