import * as axios from 'axios'; 
import { BASE_URL } from '../baseURL'; 


/**
 * Methode pour réccuperer les fiche de l'utilisateur par son statut d'envoi
 * @param {boolen} status 
 */
export async function getCardByStatusSendByLimit(status, limit){
    return axios.get(`${BASE_URL}/card?status-card=${status}&limit=${limit}`);
}
/**
 * Methode pour réccuperer les fiche de l'utilisateur qui ont été envoyé
 * @param {boolen} status 
 */
export async function getCardByStatusSendTrueByLimit(status, limit){
    return axios.get(`${BASE_URL}/card?card-send=${status}&limit=${limit}`);
}