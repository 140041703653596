import React, {Component} from 'react'; 
import { postCMS } from '../../../../API/Admin/CMS/Cms'; 
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import { CustomError, CustomInput, CustomTextarea } from '../../../../Shared/Components/Form/component-form';
import SnackBarSuccess from '../../../../Shared/Components/SnackBar/SnackBarSuccess';
import SnackBarWarning from '../../../../Shared/Components/SnackBar/SnackBarWarning';

export default class NewCMS extends Component{
    constructor(props){
        super(props)
        this.state = {
            statusSnackWarn: false, 
            statusSnackSuccess: false, 
        }

        console.log(this.props.match.params.id)
    }

    /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} 
                    message={'L\'enregistrement a echoué'} />
            );  
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} 
                    message={'Les données ont bien été enregistrés'} />
            ) 
        }
    }

    CMSSchema = Yup.object().shape({
        title: Yup.string().required('Obligatoire'),  
        content: Yup.string().required('Obligatoire'), 
    }); 

    submit = (values, actions) => {
        console.log(values)
        postCMS(this.props.match.params.id, values).then(() => {
            this.setState({statusSnackSuccess: true}); 
        }, () => {
            this.setState({statusSnackWarn: true}); 
        })
    }

    _displayContent(){
        return(
            <div className="container container_primary_admin mt-3">
                <div className="row">
                    <div className="col-12">
                        <Formik 
                            onSubmit={this.submit} 
                            initialValues={{title: '', content: ''}}
                            validateOnChange={false}   
                            validationSchema={this.CMSSchema}
                        >
                            {({ handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit} className="bg-white p-5 d-flex flex-column border">
                                    <Field type="text" name="title" label="Titre" component={CustomInput}/> 
                                    <ErrorMessage name="title" component={CustomError}/>

                                    <Field type="text" name="content" label="Contenu" component={CustomTextarea}/> 
                                    <ErrorMessage name="content" component={CustomError}/>

                                    
                                    <button type="submit" disabled={isSubmitting} className="btn btn-small btn-primary">
                                        Valider
                                    </button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        ); 
    }

    render(){
        return(
           <div className="container">
                {this._displayContent()}
                {this._displaySnackBarWarning()}
                {this._displaySnackBarSuccess()}
            </div>
        ); 
    }
}