import React, {Component} from 'react'; 
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import { CustomError, CustomInput } from '../../Shared/Components/Form/component-form';
import Footer from '../../Shared/Components/footer/Footer';
import { ButtonSubmitLoader } from '../../Shared/Components/Form/button-form';
import { identificationUser } from '../../Shared/Auth/auth';
import Button from '@material-ui/core/Button';
import App from '../../App';

export default class Signup extends Component{
    constructor(props){
        super(props)
        this.state = {
            login: undefined, 
            password: undefined, 
            statusSucess: false, 
            statusMessage: false, 
            messageSucess: "", 
            messageError: "", 
            isLoading: false
        }
    }

    signupSchema = Yup.object().shape({
        login: Yup.string().email('Votre identifiant n\'est pas valide.').required('Votre identifiant est obligation.'), 
        password: Yup.string().required('Votre mot de passe est obligatoire.').min(6, 'Votre mot de passe n\'est pas valide.'), 
    });

    submit = (values, actions) => {
        this.setState({isLoading: true}); 

        identificationUser(values).then((response) => {
            this.setState({isLoading: false, statusMessage: false, messageError: "", statusSucess: true});
        }, (err) => {
            actions.resetForm()
            if(err.response.status === 400){
                this.setState({isLoading: false, statusMessage: true, messageError: "Vos identifiants ne sont pas valide."})
            }else{
                this.setState({isLoading: false, statusMessage: true, messageError: "Une erreur a été rencontré! Réessayer ulterieurement."})
            }
        }); 
    }

    _displayButton(isSubmitting){
        if(this.state.isLoading){
            return <ButtonSubmitLoader />
        }else{
            return(
                <button type="submit" disabled={isSubmitting} className="btn btn-small">
                    Se connecter
                </button>
            ); 
        }
    }

    render(){
        return(
            <div style={{height: '100%', width: '100%'}}>
                {
                    !this.state.statusSucess ? (
                        <div className="container_primary container_sign">
                            <div className ="container">
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12 col-xl-6 col-lg-8">
                                    <Formik 
                                        onSubmit={this.submit} 
                                        initialValues={{login: '', password: ''}}
                                        validateOnChange={false}   
                                        validationSchema={this.signupSchema}
                                    >
                                        {({ handleSubmit, isSubmitting, values, errors }) => (
                                            <form onSubmit={handleSubmit} className="container_form_sign">
                                                <h1 style={{color: '#3c6382', marginBottom: 25}}>Connexion</h1>
                                                <p>Mot de passe oublié?</p>
                                                <Field type="text" name="login" label="Identifiant" required
                                                    component={CustomInput}/> 
                                                <ErrorMessage name="login" component={CustomError}/>

                                                <Field type="password" name="password" label="Mot de passe" required
                                                    component={CustomInput}/> 
                                                <ErrorMessage name="password" component={CustomError}/>
                                                
                                                {this.state.statusMessage ? (
                                                    <div>
                                                        <p className="text-danger mb-2">{this.state.messageError}</p>
                                                    </div>
                                                ) : null}

                                                <div className="cntr_button">
                                                {this._displayButton(isSubmitting)}
                                                </div>
                                                
                                            </form>
                                        )}
                                    </Formik>
                                    </div>
                                </div>
                            </div>
                            <div className="container_footer">
                                <Footer />
                            </div>
                        </div>
                    ) : (
                        <App />
                    )
                }
            </div>
        )
    }
}