import React, { Component } from 'react'; 
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { CustomError, CustomTextarea, CustomInputClassic } from '../../../Shared/Components/Form/component-form';
import './Contact.css';
import { postContact } from '../../../API/contact/contact';

export default class Contact extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: false, 
            statusForm: false, 
        }
    }

    componentDidMount(){
    }

    submit = (values, actions) => {
        this.setState({isLoading: true}); 
        postContact().then((response) => {
            this.setState({isLoading: false, statusForm: true}); 
        }, (err) => {
            this.setState({isLoading: false});        
            console.log(err.response)
        })
    }

    userSchema = Yup.object().shape({
        firstname: Yup.string().required('Votre prenom est obligatoire').max(255), 
        lastname: Yup.string().required('Votre nom est obligatoire').max(255), 
        content: Yup.string().required('Votre message est obligatoire'), 
        email: Yup.string().required('L\'e-mail est obligatoire').email('L\'e-mail n\'est pas valide'),  
    }); 

    _displayButton(isValide, isSubmitting){
        if(this.state.statusForm){
            return(
                <button className="btn btn-small">
                    Message envoyé
                </button>
            ); 
        }else if(this.state.isLoading){
            return(
                <button type="submit" disabled={isSubmitting} className="btn btn-small">
                    <img className="loader_button" src={require('../../../Shared/images/loader.gif')} alt="Chargement" />
                </button>
            ); 
        }else{
            return(
                <button type="submit" disabled={isSubmitting} className="btn btn-small">
                    valider
                </button>
            ); 
        }
    }

    render(){
        return(
            <div className="primary background_contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12 container_form">
                            <h2>Nous contacter</h2>
                            <Formik 
                                onSubmit={this.submit} 
                                initialValues={{firstname: '', lastname: '', email: '', content: ''}}
                                validateOnChange={false}   
                                validationSchema={this.userSchema}
                            > 
                                {({ handleSubmit, isSubmitting, isValid }) => (
                                    <form onSubmit={handleSubmit} className="form">
                                        <div className="sub_container_form">
                                            <div className="cntr_elt_form">
                                                <Field type="text" name="lastname" placeholder="Votre nom" component={CustomInputClassic}/> 
                                                <ErrorMessage name="lastname" component={CustomError}/>
                                            </div>
                                            <div className="cntr_elt_form">
                                                <Field type="text" name="firstname" placeholder="Votre prénom" component={CustomInputClassic}/> 
                                                <ErrorMessage name="firstname" component={CustomError}/>
                                            </div>
                                        </div>
                                        <div className="sub_container_form">
                                            <div className="cntr_elt_form">
                                                <Field type="email" name="email" placeholder="Votre adresse e-amil" component={CustomInputClassic}/> 
                                                <ErrorMessage name="email" component={CustomError}/>
                                            </div>
                                        </div>
                                        <div className="sub_container_form">
                                            <div className="cntr_elt_form">
                                                <Field type="text" name="content" placeholder="Votre message" component={CustomTextarea}/> 
                                                <ErrorMessage name="content" component={CustomError}/>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex justify-content-center mt-3">
                                            {this._displayButton(isValid, isSubmitting)}
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        ); 
    }
}