import React, {Component} from 'react'; 
import { updateCMS } from '../../../../API/Admin/CMS/Cms'; 
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import { CustomError, CustomInput, CustomTextarea } from '../../../../Shared/Components/Form/component-form';
import SnackBarSuccess from '../../../../Shared/Components/SnackBar/SnackBarSuccess';
import SnackBarWarning from '../../../../Shared/Components/SnackBar/SnackBarWarning';

export default class UpdateCMS extends Component{
    constructor(props){
        super(props)
        this.state = {
            id: undefined, 
            isLoaderData: false, 
            title: undefined, 
            content: undefined, 
            statusSnackWarn: false, 
            statusSnackSuccess: false, 
        }
    }

    componentDidMount(){
        this.setState({
            id: this.props.location.query.id, 
            title: this.props.location.query.title, 
            content: this.props.location.query.content, 
            isLoaderData: true
        }); 
    }

    /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} 
                    message={'L\'enregistrement a echoué'} />
            );  
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} 
                    message={'Les données ont bien été enregistrés'} />
            ) 
        }
    }

    CMSSchema = Yup.object().shape({
        title: Yup.string().required('Obligatoire'),  
        content: Yup.string().required('Obligatoire'), 
    }); 

    submit = (values, actions) => {
        updateCMS(this.props.match.params.id, this.state.id,  values).then(() => {
            this.setState({statusSnackSuccess: true}); 
        }, () => {
            this.setState({statusSnackWarn: true}); 
        })
    }

    _displayContent(){
        return(
            <div className="container container_primary_admin mt-3">
                <div className="row">
                    {this.state.isLoaderData ? (
                        <div className="col-12">
                            <Formik 
                                onSubmit={this.submit} 
                                initialValues={{title: this.state.title, content: this.state.content}}
                                validateOnChange={false}   
                                validationSchema={this.CMSSchema}
                            >
                                {({ handleSubmit, isSubmitting }) => (
                                    <form onSubmit={handleSubmit} className="bg-white p-5 d-flex flex-column border">
                                        <Field type="text" name="title" label="Titre" component={CustomInput}/> 
                                        <ErrorMessage name="title" component={CustomError}/>

                                        <Field type="text" name="content" label="Contenu" component={CustomTextarea}/> 
                                        <ErrorMessage name="content" component={CustomError}/>

                                        
                                        <button type="submit" disabled={isSubmitting} className="btn btn-small btn-primary">
                                            Valider
                                        </button>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    ) : <div></div>}
                </div>
            </div>
        ); 
    }

    render(){
        return(
           <div className="container">
                {this._displayContent()}
                {this._displaySnackBarWarning()}
                {this._displaySnackBarSuccess()}
            </div>
        ); 
    }
}