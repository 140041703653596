import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  root: {
      background: 'red', 
      marginBottom: 100,
      width: 280
    }
};

class SnackBarWarning extends Component {

  constructor(props){
    super(props)
    this.state = {
      open: false,
    }
  }

  componentDidMount(){
    if(this.props.message !== "" || this.props.message !== undefined){
      this.setState({open: true})
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    this.props.isOpenSnackBarWarn(false)
    this.setState({ open: false });
  };

  render() {
    const { classes, message } = this.props;
    console.log(message)
    return (
      <div>
        {
          this.state.open ? 
            <Snackbar open={this.state.open} onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            ContentProps={{
              'aria-describedby': 'message-id' , 
              classes: {
                root: classes.root
              }
            }}
            message={<span id="message-id">{message}</span>}
            action={[
              // <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
              //   UNDO
              // </Button>,
              <IconButton style={{width: 50}} key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
          : <div></div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(SnackBarWarning);