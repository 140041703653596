import React, {Component} from 'react'; 
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import { CustomError, CustomInput, CustomSelect } from '../../../Shared/Components/Form/component-form';
import { ButtonSubmitLoader } from '../../../Shared/Components/Form/button-form';
import { data_type } from '../../../Shared/Data/data-option-type';
import { data_status } from '../../../Shared/Data/data-options-status';
import { upadatedUser } from '../../../API/User/userService';
import SnackBarSuccess from '../SnackBar/SnackBarSuccess';
import SnackBarWarning from '../SnackBar/SnackBarWarning';

export default class PreviewFormProfil extends Component{
    constructor(props){
        super(props)
        this.state = {
            statusProfil: "", 
            civility: "",
            buisness: "",
            lastname : "", 
            firstname: "",  
            email: "", 
            plainPassword: "", 
            repeatPassword: "",
            title: "Modifier mon profil", 
            isLoading: false, 
            isDataLoader: false, 
            idUser: undefined,
            statusSnackWarn: false, 
            statusSnackSuccess: false, 
        }
    }

    componentDidMount(){
        const u = this.props.user
        this.setState({
            statusProfil: u.status, civility: u.civility, buisness: u.buisness,
            lastname : u.lastname, firstname: u.firstname, isDataLoader: true, 
        });
        if(u.email !== undefined){
            this.setState({email: u.email, idUser: u.id_user});
        }else{
            this.setState({email :u.mail, idUser: u.id, title: "Modifier le profil"}); 
        }
    }

    /**
     * Enregistrement du formulaire
     */
    submit = (values, actions) => {
        this.setState({isLoading: true}); 
        upadatedUser(this.state.idUser, values).then(() => {
            // actions.resetForm();
            this.setState({
                isLoading: false, statusSnackSuccess: true, statusSnackWarn: false, messageError: "", statusMessage: false
            }); 
        }, (error) => {
            actions.resetForm();
            this.setState({
                isLoading: false, statusSnackSuccess: false, statusSnackWarn: true, messageError: "", statusMessage: false
            }); 
        })
    } 

    /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} 
                    message={'Vos informations n\'ont pas été enregistrés!'} />
            );  
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} 
                    message={'Vos informations ont bien été enregistrés!'} />
            ) 
        }
    }
    
    /**
     * Schéma formulaire
     */
    updateSchema = Yup.object().shape({
        email: Yup.string().email('L\'adresse de messagerie n\'est pas valide').required('L\'adresse de messagerie est obligatoire'), 
        civility: Yup.string().required('Votre civilité est obligatoire').max(255), 
        lastname: Yup.string().required('Votre nom est obligatoire').max(255), 
        firstname: Yup.string().required('Votre prénom est obligatoire').max(255), 
        buisness: Yup.string().nullable(true), 
        statusProfil: Yup.string().required('Votre statut est obligatoire').max(255), 
    });

    /**
     * Affichage du boutton selon le statut du formualaire
     * @param {boolean} isSubmitting 
     */
    _displayButton(isSubmitting){
        if(this.state.isLoading){
            return <ButtonSubmitLoader />
        }else{
            return(
                <button type="submit" disabled={isSubmitting} className="btn btn-small">
                    Enregistrer
                </button>
            )
        }
    }

    render(){
        var c = this.state
        // console.log('user: ', c)
        return(
            <div>
                {
                    this.state.isDataLoader ? 
                    <div>
                        <Formik 
                            onSubmit={this.submit} 
                            initialValues={{
                                civility: c.civility, 
                                lastname: c.lastname, 
                                firstname: c.firstname, 
                                buisness: c.buisness, 
                                statusProfil: c.statusProfil, 
                                email: c.email, 
                            }}
                            validateOnChange={false}   
                            validationSchema={this.updateSchema}
                        >
                        {({ handleSubmit, isSubmitting, values, errors }) => (
                            <form onSubmit={handleSubmit} className="container_form_profil">

                                <h1 className="h5 mb-3" style={{color: '#3c6382'}}>{this.state.title}</h1>

                                <Field name="statusProfil" options={data_type} label="Votre statut" 
                                    component={CustomSelect} placeholder="Statut" type="text"
                                    onChange={(e) => {
                                        this.setState({statusProfil: e.target.value}); 
                                        values.statusProfil = e.target.value
                                    }} 
                                    placeholder={this.state.statusProfil}
                                /> 
                                <ErrorMessage name="statusProfil" component={CustomError}/>
                                                
                                {
                                    values.statusProfil === "professionnel" ? (
                                        <div className="w-100">
                                            <Field type="text" name="buisness" label="Dénomination entrepise" component={CustomInput}/> 
                                            <ErrorMessage name="buisness" component={CustomError}/>
                                        </div>
                                    ) : null
                                }

                                <Field name="civility" options={data_status} label="Votre civilité" 
                                    component={CustomSelect} placeholder="Titre de civilité" type="text"
                                    onChange={(e) => {
                                        this.setState({civility: e.target.value}); 
                                        values.civility = e.target.value
                                    }} 
                                    placeholder={this.state.civility}
                                /> 
                                <ErrorMessage name="civility" component={CustomError}/>

                                <Field placeholder={this.state.lastname} type="text" name="lastname" label="Votre prénom" component={CustomInput}/> 
                                <ErrorMessage name="lastname" component={CustomError}/>

                                <Field placeholder={this.state.firstname} type="text" name="firstname" label="Votre nom" component={CustomInput}/> 
                                <ErrorMessage name="firstname" component={CustomError}/>

                                <Field placeholder={this.state.email} type="email" name="email" label="Votre adresse mail" component={CustomInput}/> 
                                <ErrorMessage name="email" component={CustomError}/>

                                                        
                                {this.state.statusMessage ? (
                                    <div>
                                        <p className="text-danger mb-2">{this.state.messageError}</p>
                                    </div>
                                ) : null}

                                <div className="cntr_button">
                                    {this._displayButton(isSubmitting)}
                                </div>
                                                    
                            </form>
                            )}
                        </Formik>  
                        {this._displaySnackBarWarning()}
                        {this._displaySnackBarSuccess()}  
                    </div>
                    : <div></div>          
                }
            </div>
        )
    }
}