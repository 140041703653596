import React, { Component } from 'react'; 
import { getCMS } from '../../API/CMS/mention'; 
import Footer from '../../Shared/Components/footer/Footer'; 

export default class Confidentialite extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: true, 
            data: []
        }
    }

    componentDidMount(){
        getCMS('politique').then((response) => {
            this.setState({data: response.data, isLoading: false}); 
        }); 
    }

    render(){
        return(
            <div className="container_mention">
                <div className="container">
                    {!this.state.isLoading ? (
                        <div className="row">
                            <div className="header_title_mention">
                                <div className="voile_image_mention"></div>
                                <h1 className="title_mention">Politique de protection des données</h1>
                            </div>
                            {
                                this.state.data.map((element, index) => (    
                                    <div key={index} className="col-12">
                                        <h2 class="h2_mention">{element.title}</h2>
                                        <p>{element.content}</p>
                                    </div>
                                ))
                            }
                        </div>
                    ):  <div className="d-flex justify-content-center align-items-center h-100">
                            <img alt="logo" className="loader" src={require('../../Shared/images/loader.gif')} />
                        </div>
                    }
                </div>
                {
                    !this.state.isLoading ? (
                        <Footer />
                    ) : null
                }
            </div>
        ); 
    }
}