import React, { Component } from 'react'; 

export default class Loader extends Component{
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <img src={require('../images/loader.gif')} alt="Logo chargement" />
                    </div>
                </div>
            </div>
        ); 
    }
}