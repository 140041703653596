import React from 'react'; 
import './form.css';
// import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export const CustomInput = ({field, form, ...props}) => {
    return(
        <FormControl className="w-100 mb-2">
            <TextField { ...field} { ...props} className="w-100" />
        </FormControl>
    );
};

export const CustomInputClassic = ({field, form, ...props}) => {
    return(
        <div className="w-100 mb-2">
            {props.label !== undefined ? (
                 <label>{props.label}</label>
            ): null}
            <input className="form-control p-2" { ...field} { ...props} className="w-100" />
        </div>
    );
};

export const CustomTextarea = ({field, form, ...props}) => {
    return(
        <div className="w-100 mb-2">
            {props.label !== undefined ? (
                 <label>{props.label}</label>
            ): null}
            <textarea rows={5} { ...field} { ...props}  className="form-control" />
        </div>
    );
};

export const CustomSelect = ({field, form, ...props}) => {
    const array = props.options; 
    return(
        <FormControl className="w-100 mb-2">
            <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
            <Select  { ...field} { ...props} className="w-100" 
                inputProps={{
                    id: props.name,
                }}
            >
                {array.map((elt, index) => (
                    <MenuItem key={index} value={elt.value}>{elt.label}</MenuItem>           
                ))}
            </Select>
        </FormControl>
    );
}; 

export const CustomError = (props) => {
    return(
        <div className="text-danger mb-2 text-error-form">{props.children}</div>
    );
}