import React, { Component } from 'react'; 
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { getCardByStatusSendByLimit, getCardByStatusSendTrueByLimit } from '../../API/Card/card';
import Loader from '../../Shared/Components/Loader';
import Footer from '../../Shared/Components/footer/Footer';
import DialogsDelete from '../../Shared/Components/Dialogs/DialogsDelete';
import PreviewCard from '../../Shared/Components/user/PreviewCard';
import PreViewPDF from '../../Shared/Components/user/PreviewPDF';
import SnackBarSuccess from '../../Shared/Components/SnackBar/SnackBarSuccess'; 
import SnackBarWarning from '../../Shared/Components/SnackBar/SnackBarWarning'; 



export default class CardUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            value: 0, 
            dense: false,
            card: [], 
            isLoading: true, 
            title_grid: "", 
            isLoaderData: false, 
            statusPDF: false,
            uuidCardPDF: undefined, 
            statusNoData: false, 
            statusDialogs: false, 
            idCardDelete: undefined, 
            statusSnackWarn: false,
            statusSnackSuccess: false
        }
    }

    componentDidMount(){
        this.getCardSend(); 
    }

    /**
     * Changement du menu
     */
    handleChange = (event, value) => {
        this.setState({ isLoaderData: false, value });
        if(value === 0){
            this.getCardSend(); 
        }else if(value === 1){
            this.getCardTrue(); 
        }else if(value === 2){
            this.getCardFalse(); 
        }
    };

    /**
     * ***********************API***********************
     */

    /**
     * Réccuperation des fiches en cours
     */
    getCardFalse(){
        this.setState({title_grid: 'Fiches en cours', card: []})
        getCardByStatusSendByLimit(false, 2000).then((res) => {
            this.setState({card: res.data, isLoading: false, isLoaderData: true, statusNoData: false}); 
            if(res.data.length === 0){
                this.setState({
                    statusNoData: true
                }); 
            }
        }, (error) => {
            this.setState({isLoaderData: true,  isLoading: false, isLoaderData: true})
        }); 
    }

    /**
     * Réccuperation des fiches en attente
     */
    getCardTrue(){
        this.setState({title_grid: 'Fiches en attente', card: []})
        getCardByStatusSendByLimit(true, 2000).then((res) => {
            this.setState({card: res.data, isLoading: false, isLoaderData: true, statusNoData: false}); 
            if(res.data.length === 0){
                this.setState({
                    statusNoData: true
                }); 
            }
        }, () => {
            this.setState({isLoaderData: true,  isLoading: false, isLoaderData: true})
        }); 
    }

    /**
     * Réccuperation des fiches envoyées
     */
    getCardSend(){
        this.setState({title_grid: 'Fiches envoyées', card: []})
        getCardByStatusSendTrueByLimit(true, 2000).then((res) => {
            console.log(res)
            this.setState({card: res.data, isLoading: false, isLoaderData: true, statusNoData: false}); 
            if(res.data.length === 0){
                this.setState({
                    statusNoData: true
                }); 
            }
        }, (error) => {
            this.setState({isLoaderData: true,  isLoading: false, isLoaderData: true})
        }); 
    }

    /**
     * Suppressionde la fiche
     * @param {boolen} status 
     */
    isDeleteCard(status){
        if(status){
            this.setState({statusSnackWarn: false})
            this.state.card.map((c, index) => {
                if(c.id_card === this.state.idCardDelete){
                    this.state.card.splice(index, 1)
                }
            }); 
            if(this.state.card.length === 0){
                this.setState({statusNoData: true, statusDialogs: false, idCardDelete: undefined}); 
            }else{
                this.setState({isLoaderData: true, statusDialogs: false, idCardDelete: undefined}); 
            }
            // this.setState({statusSnackWarn: true})
            this.setState({statusSnackSuccess: true})
        }else{
            this.setState({statusDialogs: false, idCardDelete: undefined}); 
        }
    }

    /**
     * ************************ALERT************************
     */

    /**
     * Affichage alerte
     */
    _displayDialogs(id){
        if(this.state.statusDialogs){
            return (
                <DialogsDelete title="Confirmation de suppression"
                    content="Êtes-vous certain de vouloir supprimer cette fiche?"
                    isDeleteCard={this.isDeleteCard.bind(this)}
                />
            );
        }
    }

    /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} message={'Fiche non supprimée!'} />
            ) 
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} message={'Fiche supprimée'} />
            ) 
        }
    }
    
    /**
     * Logique <PreviewCard />
     */
    recepStatePreviewCard = (statusDialogs, idCardDelete) => {
        this.setState({statusDialogs: statusDialogs, idCardDelete, idCardDelete}); 
    }

    /**
     * Affichage du menu
     */
    _displayTabsPaper(){
        return(
            <Paper className="mt-2" square>
                <Tabs value={this.state.value} indicatorColor="primary" textColor="primary"
                onChange={this.handleChange} className="d-flex justify-content-center">
                    <Tab label="Fiches envoyées" />
                    <Tab label="fiche en attente"/>
                    <Tab label="Fiche en cours" />
                </Tabs>
            </Paper>
        ); 
    }

    /**
     * **************PDF**************
     */

    /**
     * Logique preview pdf
     */
    recepStatePreviewPDF = (statusPDF, uuidCardPDF) => {
        this.setState({statusPDF: statusPDF, uuidCardPDF: uuidCardPDF}); 
    }

    /**
     * DisplayPDF
     */
    _displayPDF(uuid){
        if(this.state.statusPDF){
            return(
                <PreViewPDF marginTop={80} recepStatePreviewPDF={this.recepStatePreviewPDF.bind(this)} 
                    uuidCard={this.state.uuidCardPDF} /> 
            )
        }
    }

    /**
     * Affichage des fiches utilisateurs
     */
    _displayCard(){
        console.log(this.state.card)
        return(
            <div className="d-flex justify-content-center">
                <Grid item xs={12} md={8} className="pt-3 mt-3 container_grid">
                    <Typography variant="h6" style={{color: '#3c6382'}}>
                        {this.state.title_grid}
                    </Typography>
                    {this.state.isLoaderData ? (
                        <div>
                            <List dense={this.state.dense}>
                                {!this.state.statusNoData ? (
                                    <div>
                                        <PreviewCard card={this.state.card} 
                                            recepStatePreviewCard={this.recepStatePreviewCard.bind(this)}
                                            recepStatePreviewPDF={this.recepStatePreviewPDF.bind(this)} />
                                    </div>
                                ): (
                                    <div>
                                        <p>Aucune fiche métré disponible dans cette rubrique.</p>
                                    </div>
                                )}
                            </List>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </Grid>
            </div>
        )
    }

    render(){
        return(
            <div className="container_user">
                <div className="container">
                    {!this.state.isLoading ? (
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    {this._displayDialogs()}
                                    {this._displaySnackBarWarning()}
                                    {this._displaySnackBarSuccess()}
                                    {this._displayTabsPaper()}
                                    {this._displayCard()}
                                </div>
                            </div>
                        </div>
                    ): (
                        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                            <Loader />
                        </div>
                    )}
                </div>
                {this._displayPDF()}
                <Footer />
            </div>
        ); 
    }
}