import React, {Component} from 'react'; 
import { NavLink } from 'react-router-dom'; 
import  Statistics  from '../../Shared/images/statistics.png';
import  Cms  from '../../Shared/images/cms.png';
import  Coding  from '../../Shared/images/coding.png';
import  Users  from '../../Shared/images/users.png';
import GraphicDoughnutUser from '../../Shared/Components/Graphic/GraphicDoughnutUser';
import GraphicDoughnutPay from '../../Shared/Components/Graphic/GraphicDouthnutPay';

export default class HomeAdmin extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="container_home_admin container_primary_admin">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-around">
                                <GraphicDoughnutUser period="one-month" height={200}
                                    title="Nombres d'utilisateur inscrit ce mois-ci (unitée)" />
                                <GraphicDoughnutUser period={null} height={200}
                                    title="Nombres d'utilisateur total (unitée)" />
                                <GraphicDoughnutUser period="tree-month" height={200}
                                    title="Nombres d'utilisateur inscrit dans les 3 mois (unitée)" />
                            </div>
                        </div>
                    </div>
                    <div className="container_menu_bottom">
                        {/* <NavLink className="a_header" to="/cms">
                            <div className="d-flex flex-column justify-content-center align-items-center"
                                style={{background: '#ffc823', padding: 20, height: 160, width: 160}}>
                                <img src={Cms} style={{maxHeight: 100}} />
                                <div className="mt-2" style={{color: 'white'}}>Gestion CMS</div>
                            </div>
                        </NavLink> */}
                        <NavLink className="a_header" to="/user-list">
                            <div className="d-flex flex-column justify-content-center align-items-center"
                                style={{background: '#16a085', padding: 20, height: 160, width: 160}}>
                                <img src={Users} style={{maxHeight: 100}} />
                                <div className="mt-2" style={{color: 'white'}}>Les utilisateurs</div>
                            </div>
                        </NavLink>
                        {/* <NavLink className="a_header" to="/administration">
                            <div className="d-flex flex-column justify-content-center align-items-center"
                                style={{background: '#1e3799', padding: 20, height: 160, width: 160}}>
                                <img src={Coding} style={{maxHeight: 100}} />
                                <div className="mt-2" style={{color: 'white'}}>Console</div>
                            </div>
                        </NavLink> */}
                        {/* <NavLink className="a_header" to="/administration">
                            <div className="d-flex flex-column justify-content-center align-items-center"
                                style={{background: '#ff5d00', padding: 20, height: 160, width: 160}}>
                                <img src={Statistics} style={{maxHeight: 100}} />
                                <div className="mt-2" style={{color: 'white'}}>Statistiques</div>
                            </div>
                        </NavLink> */}
                    </div>
                </div>
            </div>
        ); 
    }
}