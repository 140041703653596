import React, { Component } from 'react'; 
import ScrollableAnchor from 'react-scrollable-anchor'
import './Home.css';
import Operation from './section-one/Operation';
import Action from './secton-two/Acton';
import Contact from './Contact/Contact';
import Footer from '../../Shared/Components/footer/Footer'

export default class Home extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: true
        }
    }

    componentDidMount(){
        this.styleFormDown(); 
    }

    styleFormDown(){
        var elt = document.getElementById('forme_down'); 
        window.addEventListener('resize', (e) => {
            var currentWidth = window.innerWidth / 2 ; 
            elt.style.borderLeft = currentWidth + 'px solid white'; 
            elt.style.borderRight = currentWidth + 'px solid white'; 
        }); 
        var currentWidth = window.innerWidth / 2; 
        elt.style.borderLeft = currentWidth + 'px solid white'; 
        elt.style.borderRight = currentWidth + 'px solid white'; 
        document.getElementById('app').style.overflowX = "hidden"; 
        this.setState({isLoading: false})
    }

    render(){
        const source_phone_header = require('../../Shared/images/phone-home.png'); 
        const source_app = require('../../Shared/images/app-store.png');     
        const source_google = require('../../Shared/images/google-play.png'); 
        return(
            <div className="primary">
                <div className="header">
                    <div className="header-elt">
                        <h1>Métré Pro</h1>
                        <p className="text-header">
                            L'application mobile de mesure destinée aux profesisonels de la menuiserie.
                            Créez et envoyez vos fiches métrés rapidement et facilement à vos fournisseurs.
                        </p>
                        <div className="container_image">
                            <a target="_blank" href="https://appstore.com/metrepro">
                                <img alt="logo app store" src={source_app} className="logo_store" />
                            </a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bvservices.metrepro">
                                <img alt="logo google play" src={source_google} className="logo_store" />
                            </a>
                        </div>
                    </div>
                    <img alt="logo téléphone" className="header_img_phone" src={source_phone_header}/>
                    <a className="forme" id="forme_down" href="#presentation">
                        {null}
                    </a>
                </div>
                <div className="container">
                    <ScrollableAnchor id={'presentation'}>
                        <Operation />
                    </ScrollableAnchor>
                    <div className="cntr_rond">
                        <div className="hr-left"></div>
                        <div className="rond"></div>
                        <div className="hr-rigth"></div>
                    </div>
                    <ScrollableAnchor id={'fonctionnement'}>
                        <Action />
                    </ScrollableAnchor>
                    <div className="cntr_rond">
                        <div className="hr-left"></div>
                        <div className="rond"></div>
                        <div className="hr-rigth"></div>
                    </div>
                    <ScrollableAnchor id={'contact'}>
                        <Contact />
                    </ScrollableAnchor>
                </div>
                <ScrollableAnchor id={'footer'}>
                    <Footer />
                </ScrollableAnchor>
            </div>
        ); 
    }
}