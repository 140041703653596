import React, {Component} from 'react'; 
import { NavLink } from 'react-router-dom'; 

export default class Cms extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="container container_primary_admin">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content flex-column align-items-center">
                            <NavLink className="navbar-brand" to="/view/cgv">
                                Conditions générales de vente
                            </NavLink> 
                            <NavLink className="navbar-brand" to="/view/cgu">
                                Conditions générales d'utilisation
                            </NavLink>
                            <NavLink className="navbar-brand" to="/view/politique">
                                Politique de protection des données
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        ); 
    }
}