import React, { Component } from 'react'; 
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import FolderIcon from '@material-ui/icons/Folder';
// import DeleteIcon from '@material-ui/icons/Delete';

import List from '@material-ui/core/List';

import { getCardByStatusSendByLimit, getCardByStatusSendTrueByLimit } from '../../API/Card/card';
import Loader from '../../Shared/Components/Loader';
import Footer from '../../Shared/Components/footer/Footer';

import DialogsDelete from '../../Shared/Components/Dialogs/DialogsDelete'
import SnackBarWarning from '../../Shared/Components/SnackBar/SnackBarWarning';
import SnackBarSuccess from '../../Shared/Components/SnackBar/SnackBarSuccess';

export default class CardUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            value: 0, 
            dense: false,
            card: [], 
            isLoading: true, 
            title_grid: "", 
            isLoaderData: false, 
            statusPDF: false,
            uuidCardPDF: undefined, 
            statusNoData: false, 
            statusDialogs: false, 
            idCardDelete: undefined, 
            statusSnackWarn: false,
            statusSnackSuccess: false
        }
    }

    componentDidMount(){
        
    }

     /**
     * Affichage des fiches utilisateurs
     */
    _displayFacture(){
        console.log(this.state.card)
        return(
            <div className="d-flex justify-content-center">
                <Grid item xs={12} md={8} className="pt-3 mt-3 container_grid">
                    <Typography variant="h6" style={{color: '#3c6382'}}>
                        {this.state.title_grid}
                    </Typography>
                    {this.state.isLoaderData ? (
                        <div>
                            <List dense={this.state.dense}>
                                {!this.state.statusNoData ? (
                                    <div>
                                        {/* <PreviewCard card={this.state.card} 
                                            recepStatePreviewCard={this.recepStatePreviewCard.bind(this)}
                                            recepStatePreviewPDF={this.recepStatePreviewPDF.bind(this)} /> */}
                                    </div>
                                ): (
                                    <div>
                                        <p>Aucune fiche métré disponible dans cette rubrique.</p>
                                    </div>
                                )}
                            </List>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </Grid>
            </div>
        )
    }


    render(){
        return(
            <div className="container_user">
                <div className="container">
                    {!this.state.isLoading ? (
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    
                                </div>
                            </div>
                        </div>
                    ): (
                        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                            <Loader />
                        </div>
                    )}
                </div>
                <Footer />
            </div>
        ); 
    }
}