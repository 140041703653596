import React, {Component} from 'react'; 
import Loader from '../../../../Shared/Components/Loader';
import { getCardByUser } from '../../../../API/Admin/Card/Card';
import checked from '../../../../Shared/images/checked.png'; 
import notChecked from '../../../../Shared/images/cancel.png'; 
import Avatar from '@material-ui/core/Avatar';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'; 
import arrowRigth from '../../../../Shared/images/rigth-arrow.png'; 
import { BASE_URL } from '../../../../API/baseURL';
import SnackBarSuccess from '../../../../Shared/Components/SnackBar/SnackBarSuccess';
import SnackBarWarning from '../../../../Shared/Components/SnackBar/SnackBarWarning';

export default class PreviewCardUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoaderData: true, 
            card: [], 
            isCardExist: false, 
            idUser: undefined, 
            statusSnackWarn: false, 
            statusSnackSuccess: false, 
        }
    }

    componentDidMount(){
        this.setState({idUser: this.props.user.id});
        this.getCardUser(this.props.user.id);
    }

    getCardUser(id){
        getCardByUser(id).then((response) => {
            this.setState({card: response.data, isLoaderData: false, isCardExist: true}); 
        }).catch((err) => {
            this.setState({isLoaderData: false, statusSnackWarn: true}); 
        });
    } 

     /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} 
                    message={'Aucune fiche existante pour cette utilisateur'} />
            );  
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} 
                    message={''} />
            ) 
        }
    }

    /**
     * Envoi l'image correspondante aul boolean
     * @param {boolean} status 
     */
    _diplayImageStatus(status){
        if(status){
            return(
                <img style={{height: 20}} src={checked} alt="checked"/>
            ); 
        }else{
            return(
                <img style={{height: 20}} src={notChecked} alt="not checked" />
            )
        }
    }

    _displayTable(){
        return(
            <div>
                {
                    !this.state.isLoaderData ?
                    (
                        <div>
                            {
                                this.state.isCardExist ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{textAlign: 'center'}} scope="col">id</th>
                                                <th style={{textAlign: 'center'}} scope="col">Statut fiche</th>
                                                <th style={{textAlign: 'center'}} scope="col">Statut d'envoi</th>
                                                <th style={{textAlign: 'center'}} scope="col">Uuid</th>
                                                <th style={{textAlign: 'center'}} scope="col">PDF</th>
                                                <th style={{textAlign: 'center'}} scope="col">Voir</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.card.map((card) => (
                                                    <tr key={card.id_card}>
                                                        <th scope="row">{card.id_card}</th>
                                                        <td style={{textAlign: 'center'}}>{this._diplayImageStatus(card.status_card)}</td>
                                                        <td style={{textAlign: 'center'}}>{this._diplayImageStatus(card.send_card)}</td>
                                                        <td>{card.uuid_card}</td>
                                                        <td style={{cursor: 'pointer'}}>
                                                            <Avatar>
                                                                <RemoveRedEye style={{cursor: 'pointer',  height: 18}}
                                                                    onClick={() => {
                                                                        this.props.recepStatePreviewPDF(true, 
                                                                            BASE_URL + "/pdf/card/" + card.uuid_card + ".pdf", 
                                                                            card.id_card, 
                                                                            'card'
                                                                            )
                                                                    }} />
                                                            </Avatar>
                                                        </td>
                                                        <td style={{cursor: 'pointer'}}>
                                                            <img style={{height: 20}} src={arrowRigth} alt="arrow rigth"
                                                                onClick={() => {
                                                                    this.props.recepStatePreviewCard(true, card.id_card); 
                                                                }}/>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                    ) :
                    <div>
                        <Loader />
                    </div>
                }
                {this._displaySnackBarWarning()}
                {this._displaySnackBarSuccess()}
            </div>
        )
    }

    render(){
        return(
            <div>
                {this._displayTable()}
            </div>
        ); 
    }
}