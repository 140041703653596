import React, {Component} from 'react'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default class DialogsDelete extends Component{
    constructor(props){
        super(props)
        this.state = {
            open: false
        }
    }

    componentDidMount(){
       if(this.props.title !== "" || this.props.title !== undefined){
           this.setState({open: true})
       }
    }

    handleClose = (status) => {
        this.props.isDeleteCard(status);
    };

    render(){
        return(
            <div>
                {
                    this.state.open ?
                    <Dialog
                        open={this.state.open}
                        // onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.props.content}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.handleClose(true)}} color="primary">
                            Supprimer
                            </Button>
                            <Button onClick={() => {this.handleClose(false)}} color="primary" autoFocus>
                            Annuler
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : <div></div>
                }
            </div>
        )
    }
}