import React from 'react'; 
import './form.css'

export const ButtonSubmitClassic = () => {
    return(
        <button className="btn btn-small"></button>
    ); 
}

export const ButtonSubmitLoader = () => {
    return(
        <button className="btn btn-small p-0">
            <img src={require('../../../Shared/images/loader.gif')} className="loader_button" />
        </button>
    )
}