import React, {Component} from 'react'; 
import { getAllSubscriptionByUser, getLastSubscriptionByUser } from '../../../../API/Admin/Subscription/Subscription';
import Loader from '../../../../Shared/Components/Loader';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'; 
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment'; 
import 'moment/locale/fr'; 
import { BASE_URL } from '../../../../API/baseURL';
import SnackBarWarning from '../../../../Shared/Components/SnackBar/SnackBarWarning';
import SnackBarSuccess from '../../../../Shared/Components/SnackBar/SnackBarSuccess';

export default class PreviewSubsciptionUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoaderData: true, 
            isExistSubscription: false, 
            dateFinish: undefined, 
            bill: [], 
            statusSnackWarn: false, 
            statusSnackSuccess: false, 
        }
    }

    componentDidMount(){
        this._getLastSubscription()
    }

    _getLastSubscription(){
        getLastSubscriptionByUser(this.props.user.id, true).then((response) => {
            this.setState({isExistSubscription: true, dateFinish: response.data[0]['date_finish']});
            this._getAllSubscription()
        }).catch((error) => {
            if(error.response.status === 404){
                this._getAllSubscription()
            };
        });
    }

    _getAllSubscription(){
        getAllSubscriptionByUser(this.props.user.id).then((response) => {
            this.setState({isLoaderData: false, bill: response.data});
        }).catch((error) => {
            if(error.response.status === 404){
                this.setState({isLoaderData: false, statusSnackWarn: true});
            };
        });
    }

     /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} 
                    message={'Aucun abonnement existant pour cette utilisateur'} />
            );  
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} 
                    message={''} />
            ) 
        }
    }

    _displaySubscription(){
        return(
            <div className="d-flex mt-3">
                {
                    this.state.isExistSubscription ? (
                        <div className="alert alert-info d-flex justify-content-center align-items-center"
                            style={{maxWidth: 600}}>
                            <p className="p-0 m-0">Abonné jusqu'au {moment(this.state.dateFinish.date).format('LL')}</p>
                        </div>
                    ) : (
                        <div className="alert alert-danger d-flex justify-content-center align-items-center"
                            style={{maxWidth: 600}}>
                            <p className="p-0 m-0">Aucun abonnement en cours</p>
                        </div>
                    )
                }
            </div>
        )
    }

    render(){
        return(
            <div>
                {
                    !this.state.isLoaderData ? 
                    (
                        <div>
                            {this._displaySubscription()}
                                {
                                    this.state.isExistSubscription ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th style={{textAlign: 'center'}} scope="col">id</th>
                                                    <th style={{textAlign: 'center'}} scope="col">Date paiement</th>
                                                    <th style={{textAlign: 'center'}} scope="col">Date de fin</th>
                                                    <th style={{textAlign: 'center'}} scope="col">Formule</th>
                                                    <th style={{textAlign: 'center'}} scope="col">Prix</th>
                                                    <th style={{textAlign: 'center'}} scope="col">Facture</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.bill.map((bill) => (
                                                        <tr key={bill.id}>
                                                            <th scope="row">{bill.id}</th>
                                                            <td style={{textAlign: 'center'}}>
                                                                {moment(bill.date_pay.date).format('LLL')}
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                {moment(bill.date_finish.date).format('LLL')}
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>{bill.formule}</td>
                                                            <td style={{textAlign: 'center'}}>
                                                                {(bill.price) * 1.20}€
                                                            </td>
                                                            <td style={{cursor: 'pointer', textAlign: 'center'}}>
                                                                {
                                                                    bill.price !== '0' ? (
                                                                        <Avatar style={{textAlign: 'center'}}>
                                                                            <RemoveRedEye style={{cursor: 'pointer',  height: 18}}
                                                                                onClick={() => {
                                                                                    this.props.recepStatePreviewPDF(true, 
                                                                                        BASE_URL + "/pdf/bill/" + bill.uuid + ".pdf", 
                                                                                        bill.id_card, 
                                                                                        'bill'
                                                                                        )
                                                                                }} />
                                                                        </Avatar>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <></>
                                    )
                                }
                            {this._displaySnackBarWarning()}
                            {this._displaySnackBarSuccess()}
                        </div>
                    ) : 
                    (
                        <Loader />
                    )
                }
            </div>
        ); 
    }
}