import axios from 'axios'; 
import { BASE_URL } from '../../baseURL'; 
/**
 * Methode pour reccuperer les touts les utilisateurs
 */
export async function getAllUserForAdmin(){
    return axios.get(`${BASE_URL}/user`); 
}

/**
 * Recherche d'utilisateur
 */
export async function getUserBySearchForAdmin(search){
    return axios.get(`${BASE_URL}/user?search=${search}`); 
}

/**
 * Recherche d'utilisateur
 */
export async function getUserGraphic(period){
    if(period === "one-month"){
        return axios.get(`${BASE_URL}/user-graphic-administrator?user-one-month=true`); 
    }else if(period === "tree-month"){
        return axios.get(`${BASE_URL}/user-graphic-administrator?user-tree-month=true`); 
    }else{
        return axios.get(`${BASE_URL}/user-graphic-administrator`); 
    }
}