import React, {Component} from 'react'; 
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import { CustomError, CustomInput } from '../../../Shared/Components/Form/component-form';
import { ButtonSubmitLoader } from '../../../Shared/Components/Form/button-form';
import { upadatedUser } from '../../../API/User/userService';
import SnackBarSuccess from '../SnackBar/SnackBarSuccess';
import SnackBarWarning from '../SnackBar/SnackBarWarning';

export default class PreviewFormPassword extends Component{
    constructor(props){
        super(props)
        this.state = {
            plainPassword: "", 
            repeatPassword: "",
            statusSucess: false, 
            statusMessage: false, 
            messageSucess: "", 
            messageError: "", 
            isLoading: false, 
            isDataLoader: false,  
            idUser: undefined, 
            statusSnackWarn: false, 
            statusSnackSuccess: false, 
        }
    }

    componentDidMount(){
        const u = this.props.user
        if(u.email !== undefined){
            this.setState({isDataLoader: true, idUser: u.id_user});
        }else{
            this.setState({isDataLoader: true, idUser: u.id}); 
        } 
    }

    submit = (values, actions) => {
        this.setState({isLoading: true}); 
        if(values.repeatPassword !== values.plainPassword){
            this.setState({isLoading: false, statusMessage: true, messageError: "Vos mots de passe ne sont pas identiques."});    
        }else{
            var form = {'plainPassword': values.plainPassword}; 
            upadatedUser(this.state.idUser, form).then(() => {
                actions.resetForm();
                this.setState({
                    isLoading: false, statusSnackSuccess: true, statusSnackWarn: false, 
                    messageError: "", statusMessage: false
                }); 
            }, (error) => {
                actions.resetForm();
                this.setState({
                    isLoading: false, statusSnackSuccess: false, statusSnackWarn: true, 
                    messageError: "", statusMessage: false
                }); 
            }); 
        }
        actions.setSubmitting(false)
    }

    /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} 
                    message={'Votre nouveau mot de passe n\'a pas été enregistré!'} />
            ) 
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} 
                    message={'Votre nouveau mot de passe a bien été enregistré!'} />
            ) 
        }
    }
    
    updatePasswordUser = Yup.object().shape({
        plainPassword: Yup.string().required('Votre mot de passe est obligatoire')
            .min(6, 'Votre mot de passe doit être 6 caractères minimum').max(255), 
        repeatPassword: Yup.string().required('Veuillez répéter votre mot de passe')
            .min(6, 'Votre mot de passe doit être 6 caractères minimum').max(255), 
    });

    _displayButton(isSubmitting){
        if(this.state.isLoading){
            return <ButtonSubmitLoader />
        }else{
            return(
                <button type="submit" disabled={isSubmitting} className="btn btn-small">
                    Enregistrer
                </button>
            )
        }
    }

    render(){
        var c = this.state
        console.log('user: ', c)
        return(
            <div>
                {
                    this.state.isDataLoader ? 
                        <div>
                             <Formik 
                                onSubmit={this.submit} 
                                initialValues={{
                                plainPassword: "", 
                                repeatPassword: ""
                                }}
                                validateOnChange={false}   
                                validationSchema={this.updatePasswordUser}
                            >
                            {({ handleSubmit, isSubmitting, values, errors }) => (
                                <form onSubmit={handleSubmit} className="container_form_profil">

                                    <h1 className="h5 mb-3" style={{color: '#3c6382'}}>Modifier le mot de passe</h1>

                                    <Field type="password" name="plainPassword" 
                                        label="Votre nouveau mot de passe" component={CustomInput}/> 
                                    <ErrorMessage name="plainPassword" component={CustomError}/>

                                    <Field type="password" name="repeatPassword" 
                                        label="Répertez votre mot de passe" component={CustomInput}/> 
                                    <ErrorMessage name="repeatPassword" component={CustomError}/>
                                                            
                                    {this.state.statusMessage ? (
                                        <div>
                                            <p className="text-danger mb-2">{this.state.messageError}</p>
                                        </div>
                                    ) : null}

                                    <div className="cntr_button">
                                        {this._displayButton(isSubmitting)}
                                    </div>
                                                        
                                </form>
                                )}
                            </Formik>
                            {this._displaySnackBarWarning()}
                            {this._displaySnackBarSuccess()}  
                        </div>
                    : <div></div>          
                }
            </div>
        )
    }
}