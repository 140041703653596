import React, {Component} from 'react'; 
import { getCMS } from '../../../../API/Admin/CMS/Cms'; 
import { NavLink } from 'react-router-dom'; 
import Loader from '../../../../Shared/Components/Loader';

export default class ViewCMS extends Component{
    constructor(props){
        super(props)
        this.state = {
            condition: [], 
            isLoading: true, 
            title: undefined
        }
    }

    componentDidMount(){
        if(this.props.match.params.id === "cgv"){
            this.setState({title: 'Conditions générale de vente'})
        }else if(this.props.match.params.id === "cgu"){
            this.setState({title: 'Conditions générale d\'utilisation'})
        }
        else if(this.props.match.params.id === "politique"){
            this.setState({title: 'Politique de protection des données'})
        }
        this.getConditionGenerale(this.props.match.params.id); 
    }

    getConditionGenerale(type){
        getCMS(type).then((response) => {
            this.setState({isLoading: false, condition: response.data})
        }, (err) => {
            this.setState({isLoading: false})
            console.error(err.response)
        });
    }

    nl2br (str, i) {
        setTimeout(() => {
            str = str.replace(/\n/g, "<"+"br/>");
            document.getElementById('element_' + i).innerHTML = str;
        },1 )    
    }

    _displayContent(){
        return(
            <div className="container container_primary_admin mt-3 mb-5">
                <div className="row">
                    <div className="col-12">
                    <h1 className="mb-3" style={{color: '#3c6382', fontSize: 23}}>{this.state.title}</h1>
                    {/* <NavLink className="navbar-brand" to={"/add/" + this.props.match.params.id}>
                        Ajouter
                    </NavLink> */}
                        {this.state.condition.map((element, i) => (
                            <div style={{position: 'relative', padding: 10}} key={element.id} className="card">
                                <div  onClick={() => {
                                    document.getElementById('view_' + i).style.display = "flex"; 
                                }}>
                                    <p style={{color: '#3c6382'}}>{element.title}</p>
                                    <div id={'element_' + i}>{this.nl2br(element.content, i)}</div>
                                </div>
                                <div className="container_cache_cms" style={{display: 'none'}} id={'view_' + i }>
                                <NavLink className="navbar-brand" 
                                    to={{pathname: "/update/" + this.props.match.params.id, query: {
                                        id: element.id, 
                                        title: element.title, 
                                        content: element.content
                                    }}}>
                                     <button className="btn">
                                        Modifier
                                    </button>
                                </NavLink>

                                    <button className="btn m-2" onClick={() => {
                                        document.getElementById('view_' + i).style.display = "none"
                                    }}>
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ); 
    }

    render(){
        return(
           <div className="container">
               {
                !this.state.isLoading ? this._displayContent() : 
                <div className="d-flex justify-content align-items-center">
                    <Loader/>
                </div>
                }
            </div>
        ); 
    }
}