import axios from 'axios';
import { BASE_URL } from '../../API/baseURL'; 

export const onSignOut = () => localStorage.clear()

export  async function isSignedIn() {
    const ID_TOKEN = await localStorage.getItem('idToken')
    const JWT = await localStorage.getItem('jwt')

    return new Promise((resole, reject) => {
        axios.get(`${BASE_URL}/auth-tokens/` + ID_TOKEN).then((res) => {
            resole(true)
        }, (err) => {
            resole(false); 
        }).catch(err => reject(err)); 
    })
}

/**
 * Methode pour inserer les valeurs en local storage
 * @param {id token} id 
 * @param {value token} value 
 */
export function jwtToken(id, value){
    localStorage.setItem('idToken', id)
    localStorage.setItem('jwt', value)
}


/**
 * Methode de verification de l'identité de l'utilisateur
 * @param {login, password} form 
 */
export function identificationUser(form){
    return axios.post(`${BASE_URL}/auth-tokens`, form)
        .then((response) => {
            jwtToken(response.data['id'], response.data['value'])
        });
}