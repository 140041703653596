import React, {Component} from 'react'; 
import { getUserGraphic } from '../../../API/Admin/User/User';
import moment from 'moment'; 
import 'moment/locale/fr'; 
import { Doughnut } from 'react-chartjs-2';

export default class GraphicDoughnutPay extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: true, 
            u_professionnel : [], 
            u_particulier: [], 
            total: 0, 
            d_register: []
        }
    }

    componentDidMount(){
        this.getUserGraphic(); 
    }

    getUserGraphic(){
        getUserGraphic().then((response) => {
            response.data.map((elt) => {

                this.state.d_register.push(moment(elt.date.date).format('L'))

                if(elt.status === 'professionnel'){
                    this.state.u_professionnel.push({
                        'date': moment(elt.date.date).format('L'), 'status': elt.status
                    })
                }else{
                    this.state.u_particulier.push({
                        'date': moment(elt.date.date).format('L'), 'status': elt.status
                    })
                }

            });
            this.setState({total : response.data.length, isLoading: false}); 
        }).then((error) => {

        }); 
    }

    render() {
        if(!this.state.isLoading){
            const data = {
                labels: ['Particuliers (' + this.state.u_particulier.length + ')', 
                    'Professionnels (' + this.state.u_professionnel.length + ')'],
                datasets: [
                  {
                    data: [this.state.u_particulier.length, this.state.u_professionnel.length],
                    backgroundColor: ['blue', 'green'], 
                  }
                ]
            }

            const options = {
                title: {
                    display: true,  
                    text: 'Nombres d\'utilisateur incrit ce mois-ci (unitée)'
                },
                legend: {
                    display: true,  
                    position: 'top'
                }, 
                maintainAspectRatio: false 
            }
          
              return (
                <div>
                  <article className="canvas-container">
                    <Doughnut data={data} height={this.props.height} options={options}  />
                  </article>
                </div>
              );
        }else{
            return(
                <div></div>
            )
        }
     
    }

}