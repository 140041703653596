import React, {Component} from 'react'; 
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import SnackBarSuccess from '../SnackBar/SnackBarSuccess';
import SnackBarWarning from '../SnackBar/SnackBarWarning';
import { CustomInputClassic, CustomError, CustomTextarea } from '../Form/component-form';
import { ButtonSubmitLoader } from '../Form/button-form';
import { postEmail } from '../../../API/Admin/Email/Email';

export default class Message extends Component{
    constructor(props){
        super(props);
        this.state = {
            sujet_message: '', 
            body_message: '', 
            email_message: '',
            title_message: '', 
            isLoading: false, 
            statusSnackWarn: false, 
            statusSnackSuccess: false, 
        }
    }

    componentDidMount(){
        document.getElementById('app').style.overflow = 'hidden'; 
    }

    /**
     * Affichage du boutton selon le statut du formualaire
     * @param {boolean} isSubmitting 
     */
    _displayButton(isSubmitting){
        if(this.state.isLoading){
            return <ButtonSubmitLoader />
        }else{
            return(
                <button type="submit" disabled={isSubmitting} className="btn btn-small">
                    Envoyer
                </button>
            )
        }
    }

    /**
     * ********************Snack********************
     */

    /**
     * Logique warning
     */
    isOpenSnackBarWarn = (status) => {
        this.setState({statusSnackWarn: status})
    }

    /**
     * Affichahe warning
     */
    _displaySnackBarWarning(){
        if(this.state.statusSnackWarn){
            return(
                <SnackBarWarning isOpenSnackBarWarn={this.isOpenSnackBarWarn.bind(this)} 
                    message={'Une erreur a été rencontrée!'} />
            );  
        }
    }
    
    /**
     * Logique success
     */
    isOpenSnackBarSuccess = (status) => {
        this.setState({statusSnackSuccess: status})
    }

    /**
     * Affichahe success
     */
    _displaySnackBarSuccess(){
        if(this.state.statusSnackSuccess){
            return(
                <SnackBarSuccess isOpenSnackBarSuccess={this.isOpenSnackBarSuccess.bind(this)} 
                    message={'Votre message a bien été envoyé!'} />
            ) 
        }
    }

     /**
     * Enregistrement du formulaire
     */
    submit = (values, actions) => {
        this.setState({isLoading: true}); 
        postEmail(values).then(() => {
            // actions.resetForm();
            this.setState({
                isLoading: false, statusSnackSuccess: true, statusSnackWarn: false, messageError: "", statusMessage: false
            }); 
        }, (error) => {
            actions.resetForm();
            this.setState({
                isLoading: false, statusSnackSuccess: false, statusSnackWarn: true, messageError: "", statusMessage: false
            }); 
        })
    } 

    /**
     * Schéma formulaire
     */
    postMessage = Yup.object().shape({
        sujet_message: Yup.string().required('Le sujet du message est obligatoire').max(125, 'La limite est de 125 caractères'), 
        body_message: Yup.string().required('Le message est obligatoire').max(500, 'La limite est de 500 caractères'), 
        email_message: Yup.string().required('L\'adresse mail est obligatoire').email('L\'adresse mail n\'est pas valide'),
        title_message: Yup.string().required('Le sujet du message est obligatoire').max(125, 'La limite est de 125 caractères'), 
    });

    render(){
        return(
            <div className="window-other">
                <img src={require('../../images/cancel.png')} className="img-close"
                    onClick={() => {
                        document.getElementById('app').style.overflow = 'auto';
                        this.props.statusWindowMessage(false);
                    }}/>
                <div className="content-window-other">
                    <div className="card m-4">
                        <h4 className='mt-2 mb-2 ml-1'>
                            Envoyer message éléctronique
                        </h4>
                        <Formik 
                                onSubmit={this.submit} 
                                initialValues={{
                                    sujet_message: this.state.sujet_message, 
                                    body_message: this.state.body_message, 
                                    email_message: this.state.email_message, 
                                    title_message: this.state.title_message
                                }}
                                validateOnChange={false}   
                                validationSchema={this.postMessage}
                            >
                            {({ handleSubmit, isSubmitting, values, errors }) => (
                                <form onSubmit={handleSubmit} className="container_form_profil">
                                    <Field name="email_message" label="Adresse mail" 
                                        component={CustomInputClassic} placeholder="Adresse mail" type="text"
                                        onChange={(e) => {
                                            this.setState({email_message: e.target.value}); 
                                            values.email_message = e.target.value
                                        }} 
                                        placeholder={this.state.email_message}
                                    /> 
                                    <ErrorMessage name="email_message" component={CustomError}/>

                                    <Field name="sujet_message" label="Sujet du message" 
                                        component={CustomInputClassic} placeholder="Sujet du message" type="text"
                                        onChange={(e) => {
                                            this.setState({sujet_message: e.target.value}); 
                                            values.sujet_message = e.target.value
                                        }} 
                                        placeholder={this.state.sujet_message}
                                    /> 
                                    <ErrorMessage name="sujet_message" component={CustomError}/>

                                     <Field name="title_message" label="Titre du message" 
                                        component={CustomInputClassic} placeholder="Titre du message" type="text"
                                        onChange={(e) => {
                                            this.setState({title_message: e.target.value}); 
                                            values.title_message = e.target.value
                                        }} 
                                        placeholder={this.state.title_message}
                                    /> 
                                    <ErrorMessage name="title_message" component={CustomError}/>

                                    <Field name="body_message" label="Contenu du message" 
                                        component={CustomTextarea} placeholder="" type="text"
                                        onChange={(e) => {
                                            this.setState({body_message: e.target.value}); 
                                            values.body_message = e.target.value
                                        }} 
                                        placeholder={this.state.body_message}
                                    /> 
                                    <ErrorMessage name="body_message" component={CustomError}/>

                                    <div className="cntr_button">
                                        {this._displayButton(isSubmitting)}
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>      
                </div>
                {this._displaySnackBarWarning()}
                {this._displaySnackBarSuccess()}
            </div>
        )
    }
}