import React, { Component } from 'react'; 
import './Footer.css';
import { NavLink } from 'react-router-dom'; 

export default class Footer extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }

    componentDidMount(){}

    render(){
        return(
            <footer>
                <div className="container">
                    <div className="col-12">
                        <div className="container_footer">
                            <div className="cntr_link_footer">
                                <NavLink className="link_footer" to="/utilisation">Conditions d'utilisation</NavLink>
                                <NavLink className="link_footer" to="/confidentialite">Confidentialité</NavLink>
                                <NavLink className="link_footer" to="/vente">Conditions générales de vente</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container_copy">
                    <div className="copyrigth">© 2019 Métré Pro. Tous droits réservés</div>
                </div>
            </footer>
        ); 
    }
} 