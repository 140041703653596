import React, {Component} from 'react'; 
import Loader from '../../../../Shared/Components/Loader';
import { getCardPrimaryByCard } from '../../../../API/Admin/Card/Card';
import checked from '../../../../Shared/images/checked.png'; 
import notChecked from '../../../../Shared/images/cancel.png'; 
import Avatar from '@material-ui/core/Avatar';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'; 
import Button from '@material-ui/core/Button';
import { BASE_URL } from '../../../../API/baseURL';

export default class PreviewCardSingleUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoaderData: true, 
            cardPrimary: []
        }
    }

    componentDidMount(){
        this.getCardPrimaryByCard(this.props.idCard);
    }

    getCardPrimaryByCard(id){
        getCardPrimaryByCard(id).then((response) => {
            this.setState({cardPrimary: response.data, isLoaderData: false}); 
        }).catch((err) => {
            this.setState({isLoaderData: false}); 
        });
    }

    /**
     * Envoi l'image correspondante aul boolean
     * @param {boolean} status 
     */
    _diplayImageStatus(status){
        if(status){
            return(
                <img style={{height: 20}} src={checked} alt="checked"/>
            ); 
        }else{
            return(
                <img style={{height: 20}} src={notChecked} alt="not checked" />
            )
        }
    }

    _displayTable(){
        return(
            <div className="w-100 h-100" style={{
                position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99998,
                background: 'white'
            }}>
                {
                    !this.state.isLoaderData ?
                    (
                        <div>
                            <div>
                                
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'center'}} scope="col">id</th>
                                        <th style={{textAlign: 'center'}} scope="col">Statut fiche</th>
                                        <th style={{textAlign: 'center'}} scope="col">Prestation</th>
                                        <th style={{textAlign: 'center'}} scope="col">Type</th>
                                        <th style={{textAlign: 'center'}} scope="col">PDF</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {
                                        this.state.cardPrimary.map((card) => (
                                            <tr key={card.id}>
                                                <th scope="row">{card.id}</th>
                                                <td style={{textAlign: 'center'}}>{this._diplayImageStatus(card.status_child)}</td>
                                                <td>{card.delivery}</td>
                                                <td>{card.type_delivery}</td>
                                                <td style={{cursor: 'pointer'}}>
                                                    <Avatar>
                                                        <RemoveRedEye style={{cursor: 'pointer',  height: 18}}
                                                            onClick={() => {
                                                                this.props.recepStatePreviewPDF(true,
                                                                    BASE_URL + "/pdf/card-primary/" + card.uuid + ".pdf", 
                                                                    card.id, 
                                                                    'card-primary' 
                                                                )
                                                            }} />
                                                    </Avatar>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    ) :
                    <div>
                        <Loader />
                    </div>
                }
                <div className="footer_preview_pdf">
                    <Button style={{backgroundColor: '#3c6382', color: 'white'}} 
                        onClick={() => this.props.statusWindowPreviewCardSingle(false)}>
                        Retour
                    </Button>
                </div>
            </div>
        )
    }

    render(){
        return(
            <div>
                {this._displayTable()}
            </div>
        ); 
    }
}