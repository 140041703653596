import * as axios from 'axios'; 
import { BASE_URL } from '../../baseURL'; 

/**
 * Methode nouveau contact
 * @param {user, dateFinish, formule, price, datePay} form 
 */
export async function postSubscription(form){
    return axios.post(`${BASE_URL}/subscription-administrator`, form);
}

/**
 * Methode pour réccuperer les abonnements de l'utilisateur
 */
export async function getLastSubscriptionByUser(idUser, status){
    return axios.get(`${BASE_URL}/subscription-administrator?user=${idUser}&last=${status}`);
}

/**
 * Methode pour réccuperer les abonnements de l'utilisateur
 */
export async function getAllSubscriptionByUser(idUser){
    return axios.get(`${BASE_URL}/subscription-administrator?user=${idUser}`);
}