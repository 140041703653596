import React, {Component} from 'react'; 

export default class UserList extends Component{
    constructor(props){
        super(props)
        this.state = {
            users: []
        }
    }

    componentDidMount(){
        this.setState({users: this.props.users});
        console.log(this.props.users)
    }

    render(){
        return(
            <div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Adresse mail</th>
                                <th scope="col">Entreprise</th>
                                <th scope="col">Civilité</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Prénom</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.users.reverse().map((user) => (
                                    <tr key={user.id} style={{cursor: 'pointer'}} onClick={() => {
                                        this.props.onPressUserTabs(user)
                                    }}>
                                        <th scope="row">{user.id}</th>
                                        <td>{user.mail}</td>
                                        <td>{user.buisness}</td>
                                        <td>{user.civility}</td>
                                        <td>{user.lastname}</td>
                                        <td>{user.firstname}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        ); 
    }
}