import React, { Component } from 'react'; 
import './Action.css';

export default class Action extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }

    componentDidMount(){}

    render(){
        var source_arrow_rigth = require('../../../Shared/images/right-arrow.png'); 
        var source_measured = require('../../../Shared/images/measuring-tape.png'); 
        var source_mobile = require('../../../Shared/images/smartphone-call.png'); 
        var source_mail = require('../../../Shared/images/sent-mail.png'); 
        return(
            <div className="primary container_action">
                <div className="row">
                    <div className="col-12">
                        <div className="block_action">
                            <h2>Comment ça marche</h2>
                            <p>Un procédé facile entre le prestataire et le fournisseur</p>
                            <div className="cntr_block">
                                <div className="cntr_elt">
                                    <img className="img_action" src={source_measured} alt="Logo mesure"/>
                                    <p className="p_action">1. Prenez vos mesures</p>
                                </div>

                                <img className="arrow_rigth" src={source_arrow_rigth} alt="logo flèche droite"/>

                                <div className="cntr_elt">
                                    <img className="img_action" src={source_mobile} alt="Logo téléphone"/>
                                    <p className="p_action">2. Remplissez les fiches numériques</p>
                                </div>

                                <img className="arrow_rigth" src={source_arrow_rigth} alt="logo flèche droite"/>

                                <div className="cntr_elt">
                                    <img className="img_action" src={source_mail} alt="Logo envyer"/>
                                    <p className="p_action">3. Envoyez vos documents</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}