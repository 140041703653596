import React, { Component } from 'react'; 
import './header.css';
import { NavLink } from 'react-router-dom'; 

export default class Header extends Component{
    constructor(props){
        super(props)
        this.state = {
            isSignedIn: false, 
            isAdmin: false, 
        }
    }

    componentDidMount(){
        this.setState({
            isSignedIn: this.props.signedIn, 
            isAdmin: this.props.isAdmin
        });
        console.log(this.props)
    }

    signOut(){
        localStorage.clear(); 
        this.props.history.push("/accueil"); 
    }   

    _displayLinkisAdmin(){
        if(this.state.isAdmin && this.state.isSignedIn){
            return(
                <div>
                    <NavLink className="a_header" to="/administration-fae23pil">Menu</NavLink>
                </div>
            );
        }
    }
    

    _displayLinkIsSignIn(){
        if(this.state.isSignedIn && !this.state.isAdmin){
            return(
                <div>
                    <NavLink className="a_header" to="/profil">Profil</NavLink>
                    <NavLink className="a_header" to="/factures">Factures</NavLink>
                    <NavLink className="a_header" to="/fiches">Fiches</NavLink>
                    {/* <NavLink  className="a_header" to="/accueil">Déconnexion</NavLink> */}
                </div>
            );
        }
    }

    _displayLinkIsNotSignIn(){
        if(!this.state.isSignedIn){
            return(
                <div>
                    <NavLink className="a_header" to="/accueil#presentation">Présentation</NavLink>
                    <NavLink className="a_header" to="/accueil#fonctionnement">Fonctionnement</NavLink>
                    {/* <NavLink className="a_header" to="/accueil#contact">Contact</NavLink>
                    <NavLink className="a_header" to="/inscription">Inscription</NavLink>
                    <NavLink className="a_header" to="/connexion">Connexion</NavLink> */}
                </div>
            );
        }
    }

    render(){
        var source_logo = require('../../../Shared/images/dn.png')
        return(
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-12 container_header">
                            <NavLink to="/accueil">
                                <img src={source_logo} className="logo" alt="Logo métré pro"/>
                            </NavLink>

                            <div className="container_link">
                                {this._displayLinkIsNotSignIn()}
                                {this._displayLinkIsSignIn()}
                                {this._displayLinkisAdmin()}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}