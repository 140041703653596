import React, {Component} from 'react'; 
import { getUserGraphic } from '../../../API/Admin/User/User';
import { Line } from 'react-chartjs-2' 
import { Bar } from 'react-chartjs-2';
import moment from 'moment'; 
import 'moment/locale/fr'; 

export default class GraphicUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: true, 
            u_professionnel : [], 
            d_professionnel: [], 
            u_particulier: [], 
            d_particulier: [], 
            u_masculin: [], 
            u_femin: [], 
            total: 0, 
            d_register: []
        }
    }

    componentDidMount(){
        this.getUserGraphic(); 
    }

    getUserGraphic(){
        getUserGraphic().then((response) => {
            response.data.map((elt) => {

                this.state.d_register.push(moment(elt.date.date).format('L'))

                if(elt.status === 'professionnel'){
                    this.state.u_professionnel.push({
                        'date': moment(elt.date.date).format('L'), 'status': elt.status
                    })
                }else{
                    this.state.u_particulier.push({
                        'date': moment(elt.date.date).format('L'), 'status': elt.status
                    })
                }

                // console.log(elt)

                // if(elt.civility === 'monsieur'){
                //     this.state.u_masculin.push(elt)
                // }else{
                //     this.state.u_femin.push(elt)
                // }
  
            });
            this.clearArrySimple(this.state.d_register, 'd_register'); 
            this.clearArrayMultiDimension(this.state.u_professionnel, 'u_professionnel', 'd_professionnel'); 
            this.clearArrayMultiDimension(this.state.u_particulier, 'u_particulier', 'd_particulier'); 
            this.setState({total : response.data.length, isLoading: false}); 
        }).then((error) => {

        }); 
    }

    clearArrayMultiDimension(array, state, state_data){
        var i, j, len = array.length, out = [], obj = {}, x = 0; 

        for(i = 0; i < len; i++){  
            if(obj[array[i]['date']] !== undefined){
                x++; 
                array[i-x]['nb'] = x+1; 
            }else{
                x = 0; 
                array[i]['parent'] = true; 
                obj[array[i]['date']] = 0; 
            }
        }

        for(i=0; i < len; i++){
            if(array[i]['parent'] === true && array[i]['nb'] === undefined){
                delete array[i]['parent']
                array[i]['nb'] = 1;
                out.push(array[i])
            }else if(array[i]['parent'] === true && array[i]['nb'] !== undefined){
                delete array[i]['parent']
                out.push(array[i])
            }
        }

        out.map((elt) => {
            this.state[state_data].push(elt.nb)
        }); 
        this.setState({[state]: out});
    }

    clearArrySimple(array, state){
        var i, j, len = array.length, out = [], obj = {};
        for (i = 0; i < len; i++) {
          obj[array[i]] = 0;
        }
        for (j in obj) {
          out.push(j);
        }
        this.setState({[state]: out})
    }

    render() {
        if(!this.state.isLoading){
            const data = {
                labels: this.state.d_register,
                datasets: [
                  {
                    label: 'Particulier',
                    data: this.state.d_particulier,
                    fill: true,          // Don't fill area under the line
                    borderColor: 'blue'  // Line color
                  },
                  {
                      label: 'Professionel',
                      data: this.state.d_professionnel,
                      fill: false,          // Don't fill area under the line
                      borderColor: 'green'  // Line color
                    }
                ]
              }
          
              return (
                <div className="App">
                  <header className="App-header">
                    <h1>Responsive Linear chart using Chart.js</h1>
                  </header>
                  <article className="canvas-container">
                    <Line data={data} height={150}  />
                  </article>
                </div>
              );
        }else{
            return(
                <div></div>
            )
        }
     
    }

}