import * as axios from 'axios'; 
import { BASE_URL } from '../../baseURL'; 

/**
 * Methode 
 * @param {login, password} form 
 */
export function getCMS(url){
    return axios.get(`${BASE_URL}/${url}`); 
}

/**
 * Methode
 * @param {login, password} form 
 */
export function postCMS(url, form){
    return axios.post(`${BASE_URL}/${url}`, form); 
}

/**
 * Methode
 * @param {login, password} form 
 */
export function updateCMS(url, id,  form){
    return axios.put(`${BASE_URL}/${url}/${id}`, form); 
}
