import React, {Component} from 'react'; 
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import { CustomError, CustomInput, CustomSelect } from '../../Shared/Components/Form/component-form';
import { data_type } from '../../Shared/Data/data-option-type';
import { data_status } from '../../Shared/Data/data-options-status';
import Footer from '../../Shared/Components/footer/Footer';
import { createNewUser, sendEmailNewUser } from '../../API/User/userService';
import { ButtonSubmitLoader } from '../../Shared/Components/Form/button-form';

export default class Signin extends Component{
    constructor(props){
        super(props)
        this.state = {
            statusProfil: "", 
            civility: "",
            buisness: "",
            lastname : "", 
            firstname: "",  
            email: "", 
            plainPassword: "", 
            repeatPassword: "",
            statusSucess: false, 
            statusMessage: false, 
            messageSucess: "", 
            messageError: "", 
            isLoading: false
        }
    }

    signinSchema = Yup.object().shape({
        statusProfil: Yup.string().required('Votre statut est obligatoire.'), 
        civility: Yup.string().required('Votre civilité est obligatoire.'), 
        buisness: Yup.string().nullable(true), 
        lastname: Yup.string().required('Votre nom est obligatoire.'), 
        firstname: Yup.string().required('Votre prénom est obligatoire.'), 
        email: Yup.string().email('L\'adresse de messagerie n\'est pas valide').required('L\'adresse de messagerie est obligatoire'), 
        plainPassword: Yup.string().min(6, 'Votre mot de passe doit être 6 caractères minimum').required('Votre mot de passe est obligatoire.'), 
        repeatPassword: Yup.string().min(6, 'Votre mot de passe doit être 6 caractères minimum').required('Votre mot de passe est obligatoire.'), 
    });

    submit = (values, actions) => {
        this.setState({isLoading: true})
        if(values.repeatPassword !== values.plainPassword){
            this.setState({isLoading: false, statusMessage: true, messageError: "Vos mots de passe ne sont pas identiques."});    
            actions.setSubmitting(false)
        }else if(values.statusProfil === "professionnel" && values.buisness === ''){
            this.setState({isLoading: false, statusMessage: true, messageError: "Votre entreprise n'est pas renseignée."}); 
            actions.setSubmitting(false)
        }else{
            const form = values; 
            delete form.repeatPassword; 
            actions.resetForm()

            createNewUser(form).then((response) => {
                var email_user = { email: this.state.email };
                this.setState({isLoading: false, statusSucess: true, statusMessage: true, messageError: "", statusSucess: "Votre inscription a bien été enregistré."})
                sendEmailNewUser(email_user); 
            }, (err) => {
                if(err.response.status === 400){
                    this.setState({isLoading: false, statusMessage: true, messageError: "L'adresse mail est déja utilisé."})
                }else{
                    this.setState({isLoading: false, statusMessage: true, messageError: "Une erreur a été rencontré! Réessayer ulterieurement."})
                }
            })
        }
    }

    _displayButton(isSubmitting){
        if(this.state.isLoading){
            return <ButtonSubmitLoader />
        }else if(this.state.statusSucess){
            return(
                <button onClick={() => {this.props.history.push("/connexion")}} type="submit" className="btn btn-small">
                    Se connecter
                </button>
            )
        }else{
            return(
                <button type="submit" disabled={isSubmitting} className="btn btn-small">
                    S'inscrire
                </button>
            )
        }
    }

    render(){
        const c = this.state
        return(
            <div className="container_primary container_sign">
                <div className ="container">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                        <div className="col-12 col-xl-6 col-lg-8">
                        <Formik 
                            onSubmit={this.submit} 
                            initialValues={{
                                statusProfil: c.statusProfil, 
                                civility: c.civility, 
                                buisness: c.buisness, 
                                lastname: c.lastname, 
                                firstname: c.firstname, 
                                email: c.email, 
                                plainPassword: c.plainPassword, 
                                repeatPassword: c.repeatPassword
                            }}
                            validateOnChange={false}   
                            validationSchema={this.signinSchema}
                        >
                            {({ handleSubmit, isSubmitting, values, errors }) => (
                                <form onSubmit={handleSubmit} className="container_form_sign">
                                    <h1 style={{color: '#3c6382', marginBottom: 25}}>Inscription</h1>

                                    <Field name="statusProfil" options={data_type} label="Votre statut" 
                                        component={CustomSelect} placeholder="Statut" type="text"
                                        onChange={(e) => {
                                            this.setState({statusProfil: e.target.value}); 
                                            values.statusProfil = e.target.value
                                        }} 
                                        value={this.state.statusProfil}
                                    /> 
                                    <ErrorMessage name="statusProfil" component={CustomError}/>
                                    
                                    {
                                        values.statusProfil === "professionnel" ? (
                                            <div className="w-100">
                                                <Field type="text" name="buisness" label="Dénomination entrepise" component={CustomInput}/> 
                                                <ErrorMessage name="buisness" component={CustomError}/>
                                            </div>
                                        ) : null
                                    }

                                    <Field name="civility" options={data_status} label="Votre civilité" 
                                        component={CustomSelect} placeholder="Titre de civilité" type="text"
                                        onChange={(e) => {
                                            this.setState({civility: e.target.value}); 
                                            values.civility = e.target.value
                                        }} 
                                        value={this.state.civility}
                                    /> 
                                    <ErrorMessage name="civility" component={CustomError}/>

                                    <Field type="text" name="lastname" label="Votre prénom" component={CustomInput}/> 
                                    <ErrorMessage name="lastname" component={CustomError}/>

                                    <Field type="text" name="firstname" label="Votre nom" component={CustomInput}/> 
                                    <ErrorMessage name="firstname" component={CustomError}/>

                                    <Field type="email" name="email" label="Votre adresse mail" component={CustomInput}/> 
                                    <ErrorMessage name="email" component={CustomError}/>

                                    <Field type="password" name="plainPassword" label="Mot de passe" component={CustomInput}/> 
                                    <ErrorMessage name="plainPassword" component={CustomError}/>

                                    <Field type="password" name="repeatPassword" label="Répéter votre de passe" component={CustomInput}/> 
                                    <ErrorMessage name="repeatPassword" component={CustomError}/>
                                    
                                    {this.state.statusMessage ? (
                                        <div>
                                            <p className="text-danger mb-2">{this.state.messageError}</p>
                                            <p className="text-success mb-2">{this.state.messageSucess}</p>
                                        </div>
                                    ) : null}

                                    <div className="cntr_button">
                                       {this._displayButton(isSubmitting)}
                                    </div>

                                </form>
                            )}
                        </Formik>
                        </div>
                    </div>
                </div>
                <div className="container_footer">
                    <Footer />
                </div>
            </div>
        )
    }
}