import React, { Component } from 'react'; 
import Footer from '../../Shared/Components/footer/Footer'; 

export default class Mention extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: true
        }
    }

    componentDidMount(){
        
    }

    render(){
        return(
            <div className="container_mention">
                <div className="container">
                    <div className="row">
                        <div className="header_title_mention">
                            <div className="voile_image_mention"></div>
                                <h1 className="title_mention">Politique de protection des données</h1>
                            </div>

                        </div>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <img alt="logo" className="loader" src={require('../../Shared/images/loader.gif')} />
                    </div>
                </div>
                <Footer />
            </div>
        ); 
    }
}