import React, {Component} from 'react'; 
import UserList from './Components/UserList';
import '../admin.css'; 
import Loader from '../../../Shared/Components/Loader';
import { getAllUserForAdmin, getUserBySearchForAdmin } from '../../../API/Admin/User/User'
import UserSearch from './Components/UserSearch';
import UserSingle from './Components/UserSingle';
import GraphicUser from '../../../Shared/Components/Graphic/GraphicUser';
import Message from '../../../Shared/Components/Message/Message';

export default class User extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: true, 
            users: [], 
            statusUserSearch: false, 
            usersSearch: [], 
            statusPreviewUser: false,
            user: undefined, 
            statusWindowMessage: false
        }
    }

    componentDidMount(){
        this.getAllUser()
    }

    getAllUser(){
        getAllUserForAdmin().then((response) => {
            this.setState({users: response.data, isLoading: false}); 
        }).catch((err) => {
            console.log(err)
        }); 
    }

    getUserBySearch(value){
        if(value.length > 2){
            getUserBySearchForAdmin(value).then((response) => {
                this.setState({usersSearch: response.data, statusUserSearch: true}); 
            }); 
        }else{
            console.log('destroy')
            this.setState({usersSearch: [], statusUserSearch: false}); 
        }
    } 

    onPressUserTabs = (user) => {
        this.setState({user: user, statusPreviewUser: true});
    }

    onCloseUserSingle = (status) => {
        this.setState({statusPreviewUser: status})
    }

    statusWindowMessage = (status) => {
        this.setState({statusWindowMessage: status}); 
    }

    render(){
        return(
            <div className="container_primary_admin">
                {
                    !this.state.isLoading ? 
                        <div>
                            {/* <GraphicUser /> */}
                            {
                                this.state.statusPreviewUser ? 
                                (
                                    <div style={{position: 'absolute', width: '100%', minHeight: '100%'}}>
                                        <UserSingle user={this.state.user} onCloseUserSingle={this.onCloseUserSingle}/>
                                    </div>
                                )
                                :
                                (
                                    <div className="container">
                                        {
                                            this.state.statusWindowMessage ? (
                                                <Message statusWindowMessage={this.statusWindowMessage.bind(this)} />
                                            ) : <></>
                                        }
                                        <div classname="row">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <input type="text" placeholder="Rechercher un utilisateur" className="inputSearch"
                                                        onChange={(event) => this.getUserBySearch(event.target.value)}/>
                                                    <button className="btn" onClick={() => {this.statusWindowMessage(true)}}>
                                                        Envoyer message
                                                    </button>
                                                </div>
                                                {
                                                    this.state.statusUserSearch ?
                                                    <UserSearch usersSearch={this.state.usersSearch} onPressUserTabs={this.onPressUserTabs}/>
                                                    : <UserList users={this.state.users} onPressUserTabs={this.onPressUserTabs}/>
                                                }     
                                                </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    : <Loader />
                }
            </div>
        ); 
    }
}